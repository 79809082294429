/**
 * @generated SignedSource<<3ddd623219f4762dcec66d02b532dc3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ssoConfigurationId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "protocol",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentMemberAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "currentMember",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BiometricRegistrations_member"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SsoForm_member"
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SsoConfiguration",
                "kind": "LinkedField",
                "name": "ssoConfiguration",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CurrentMemberAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "currentMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Credential",
                "kind": "LinkedField",
                "name": "credentials",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SsoConfiguration",
                "kind": "LinkedField",
                "name": "ssoConfiguration",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "65cb6f66ce176b6fe26fafd05c7c98ca",
    "id": null,
    "metadata": {},
    "name": "CurrentMemberAccountQuery",
    "operationKind": "query",
    "text": "query CurrentMemberAccountQuery {\n  currentMember {\n    ...BiometricRegistrations_member\n    ...SsoForm_member\n    role\n    user {\n      ssoConfigurationId\n      id\n    }\n    organisation {\n      ssoConfiguration {\n        databaseId\n        protocol\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment BiometricRegistration_credential on Credential {\n  id\n  name\n  createdAt\n  ...EditBiometricRegistration_credential\n}\n\nfragment BiometricRegistrations_member on Member {\n  user {\n    credentials {\n      id\n      ...BiometricRegistration_credential\n    }\n    id\n  }\n}\n\nfragment EditBiometricRegistration_credential on Credential {\n  createdAt\n  id\n  name\n}\n\nfragment SsoForm_member on Member {\n  databaseId\n  organisation {\n    ssoConfiguration {\n      protocol\n      databaseId\n      id\n    }\n    id\n  }\n  user {\n    id\n    ssoConfigurationId\n    databaseId\n  }\n}\n"
  }
};
})();

node.hash = "e2dc3f603f82c74b3fadfd4052a7000b";

module.exports = node;
