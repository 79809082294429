const color = 'hsl(228, 45%, 21%)';
const bgColor = 'hsl(214, 100%, 99%)';
const primaryColor = '#FFFFFF';

export const theme = {
  color,
  bgColor,
  shadow: {
    primary: 'hsla(222, 52%, 19%, 0.08)',
    secondary: 'hsla(222, 52%, 19%, 0.06)',
  },
  hover: {
    colorH: '211',
    colorS: '64%',
    colorL: '86%',
    color: 'hsl(220, 79%, 53%)',
  },
  card: {
    bgcolor: primaryColor,
  },
  primary: {
    colorH: '220',
    colorS: '79%',
    colorL: '53%',
    color: primaryColor,
  },
  secondary: {
    colorH: '218',
    colorS: '36%',
    colorL: '82%',
    color,
  },
  tertiary: {
    colorH: '211',
    colorS: '64%',
    colorL: '94%',
    color: 'hsl(220, 79%, 53%)',
  },
  quartiary: {
    colorH: '207',
    colorS: '21%',
    colorL: '84%',
    color: primaryColor,
  },
  success: {
    colorH: '130',
    colorS: '53%',
    colorL: '46%',
    color: primaryColor,
  },
  danger: {
    colorH: '0',
    colorS: '84%',
    colorL: '49%',
    color: primaryColor,
  },
  warning: {
    colorH: '5',
    colorS: '87%',
    colorL: '70%',
    color: primaryColor,
  },
};
