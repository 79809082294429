import styled from 'styled-components';
import Card from '../../smart-design-system/Card/Card';
import * as Grid from '../../smart-design-system/Grid/Grid';
import { GlobalStyle } from '../../smart-design-system/shared/global';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserRobotXmarks } from '@fortawesome/pro-regular-svg-icons';

import Loading from '../Loading/Loading';

const Background = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const ONE_MINUTE = 3600000;

const ErrorInfo = () => {
  const lastError = sessionStorage.getItem('lastError');

  if (lastError === null || parseInt(lastError) + ONE_MINUTE < Date.now()) {
    sessionStorage.clear();
    sessionStorage.setItem('lastError', Date.now());
    window.location.reload();
    return <Loading />;
  } else {
    sessionStorage.setItem('lastError', Date.now());
  }

  return (
    <>
      <GlobalStyle />
      <Background>
        <Grid.Container>
          <Grid.Row>
            <Grid.Col offsetSm="1" sm="10" offsetLg="3" lg="6" offsetXxl="3.5" xxl="5">
              <Card>
                <h1>
                  <FontAwesomeIcon icon={faUserRobotXmarks} /> We're sorry, but something went wrong.
                </h1>
                <a href="/">Back to home</a>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </Background>
    </>
  );
};

export default ErrorInfo;
