/**
 * @generated SignedSource<<b4067fdcbed4d9328076150e936d4a9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loadTable"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "manualParagraphId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tableId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "textBlockId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "manualParagraphId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v6 = {
  "condition": "loadTable",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "tableId"
        }
      ],
      "concreteType": "Table",
      "kind": "LinkedField",
      "name": "table",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "columns",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "textBlockId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ManualParagraph",
        "kind": "LinkedField",
        "name": "manualParagraph",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "TextBlock",
        "kind": "LinkedField",
        "name": "textBlock",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TableFormFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ManualParagraph",
        "kind": "LinkedField",
        "name": "manualParagraph",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "TextBlock",
        "kind": "LinkedField",
        "name": "textBlock",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ffd844d3c4c640caf450b1ac5d324cf2",
    "id": null,
    "metadata": {},
    "name": "TableQuery",
    "operationKind": "query",
    "text": "query TableQuery(\n  $loadTable: Boolean!\n  $manualParagraphId: ID!\n  $tableId: ID!\n  $textBlockId: ID!\n) {\n  manualParagraph(id: $manualParagraphId) {\n    name\n    number\n    id\n  }\n  table(id: $tableId) @include(if: $loadTable) {\n    id\n    databaseId\n    columns\n  }\n  textBlock(id: $textBlockId) {\n    name\n    id\n  }\n  ...TableFormFragment\n}\n\nfragment TableFormFragment on Query {\n  textBlock(id: $textBlockId) {\n    id\n    databaseId\n  }\n}\n"
  }
};
})();

node.hash = "ef19511b3b0bcd5c3e5a1cef1a65dd93";

module.exports = node;
