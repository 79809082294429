import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { NavigationProvider } from './navigation-provider';

import RelayEnvironment from './RelayEnvironment';
import routes from './routes';
import RoutingContext from './routing/RoutingContext';
import createRouter from './routing/createRouter';
import RouterRenderer from './routing/RouteRenderer';

import './i18n';

const router = createRouter(routes);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://381b9733d8a543ca8b6558f16dc2cecb@o248658.ingest.sentry.io/4504553276309504',
    ignoreErrors: ['UnauthorizedError'],
    integrations: [new BrowserTracing()],
    tracesSampleRate: null,
  });
}

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <NavigationProvider>
        <Suspense fallback="loading Suspense Index...">
          <RoutingContext.Provider value={router.context}>
            <RouterRenderer />
          </RoutingContext.Provider>
        </Suspense>
      </NavigationProvider>
    </RelayEnvironmentProvider>
  </StrictMode>
);
