/**
 * @generated SignedSource<<99071057160b2d9005eba79f5508cf38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualChapterIds",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualParagraphIds",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "normIds",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualParagraphId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manualsAuditQuestionId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "normParagraphIds",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "question",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobRoleId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v16 = [
  (v2/*: any*/),
  (v7/*: any*/),
  (v15/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditPlanQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuditFormQuery_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Norms_query"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditQuestion",
            "kind": "LinkedField",
            "name": "auditQuestions",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditQuestionJobRole",
                "kind": "LinkedField",
                "name": "auditQuestionJobRoles",
                "plural": true,
                "selections": [
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobRole",
                    "kind": "LinkedField",
                    "name": "jobRole",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobTitle",
                        "kind": "LinkedField",
                        "name": "jobTitle",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualParagraph",
                "kind": "LinkedField",
                "name": "manualParagraph",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v15/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ManualChapter",
                    "kind": "LinkedField",
                    "name": "manualChapter",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NormParagraph",
                "kind": "LinkedField",
                "name": "normParagraphs",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManualChapter",
        "kind": "LinkedField",
        "name": "manualChapters",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v2/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": (v16/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NormOrganisation",
        "kind": "LinkedField",
        "name": "normOrganisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norm",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NormParagraph",
                "kind": "LinkedField",
                "name": "normParagraphs",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditQuestion",
            "kind": "LinkedField",
            "name": "auditQuestions",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditQuestionJobRole",
                "kind": "LinkedField",
                "name": "auditQuestionJobRoles",
                "plural": true,
                "selections": [
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobRole",
                    "kind": "LinkedField",
                    "name": "jobRole",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobTitle",
                        "kind": "LinkedField",
                        "name": "jobTitle",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualParagraph",
                "kind": "LinkedField",
                "name": "manualParagraph",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v15/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ManualChapter",
                    "kind": "LinkedField",
                    "name": "manualChapter",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NormParagraph",
                "kind": "LinkedField",
                "name": "normParagraphs",
                "plural": true,
                "selections": (v16/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a9ce1a8cda5bdfd747fc4944fe6d0e1",
    "id": null,
    "metadata": {},
    "name": "AuditPlanQuery",
    "operationKind": "query",
    "text": "query AuditPlanQuery(\n  $id: ID!\n) {\n  ...AuditFormQuery_query\n  ...Norms_query\n  audit(id: $id) {\n    databaseId\n    description\n    id\n    manualChapterIds\n    manualParagraphIds\n    name\n    normIds\n    auditQuestions {\n      databaseId\n      manualParagraphId\n      manualsAuditQuestionId\n      normParagraphIds\n      position\n      question\n      auditQuestionJobRoles {\n        jobRoleId\n        jobRole {\n          jobTitle {\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n      manualParagraph {\n        name\n        number\n        position\n        manualChapter {\n          position\n          id\n        }\n        id\n      }\n      normParagraphs {\n        databaseId\n        name\n        number\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment AuditFormQuery_query on Query {\n  manualChapters {\n    id\n    databaseId\n    manualParagraphIds\n    ...ExpandableCheckboxQuery_manualChapter\n  }\n  normOrganisations {\n    norm {\n      databaseId\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment ExpandableCheckboxQuery_manualChapter on ManualChapter {\n  databaseId\n  name\n  number\n  manualParagraphIds\n  manualParagraphs {\n    databaseId\n    name\n    number\n    id\n  }\n}\n\nfragment Norms_query on Query {\n  normOrganisations {\n    id\n    norm {\n      databaseId\n      name\n      normParagraphs {\n        id\n        databaseId\n        name\n        number\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "42fab18e0a2f52cf2ae16d83aeb8c2fe";

module.exports = node;
