/**
 * @generated SignedSource<<42b2cd4f1b3f32a1b1d8555c60a668fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewThreatLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Threat",
        "kind": "LinkedField",
        "name": "threat",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ThreatForm_threat"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewThreatLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Threat",
        "kind": "LinkedField",
        "name": "threat",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6df3343a9387a7771bd8c68553634df8",
    "id": null,
    "metadata": {},
    "name": "NewThreatLocalQuery",
    "operationKind": "query",
    "text": "query NewThreatLocalQuery(\n  $id: ID!\n) {\n  threat(id: $id) {\n    ...ThreatForm_threat\n    id\n  }\n}\n\nfragment ThreatForm_threat on Threat {\n  name\n  description\n  organisationId\n}\n"
  }
};
})();

node.hash = "ce84f8005dd737b07e26da4c487cde65";

module.exports = node;
