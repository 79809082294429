/**
 * @generated SignedSource<<3e5ebe13244679fe7b002b028f23f99d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "beginPublishedAt"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessUnitIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endPublishedAt"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "beginPublishedAt",
    "variableName": "beginPublishedAt"
  },
  {
    "kind": "Variable",
    "name": "businessUnitIds",
    "variableName": "businessUnitIds"
  },
  {
    "kind": "Variable",
    "name": "endPublishedAt",
    "variableName": "endPublishedAt"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsItemsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "NewsItemList_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NewsItemFilters_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewsItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NewsItemPagination",
        "kind": "LinkedField",
        "name": "newsItemPagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NewsItem",
            "kind": "LinkedField",
            "name": "newsItems",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publishedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessUnit",
        "kind": "LinkedField",
        "name": "businessUnits",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bbdd495872f90175167fe666f67d6982",
    "id": null,
    "metadata": {},
    "name": "NewsItemsQuery",
    "operationKind": "query",
    "text": "query NewsItemsQuery(\n  $beginPublishedAt: String\n  $businessUnitIds: [ID!]\n  $endPublishedAt: String\n  $limit: Int\n  $page: Int\n  $query: String\n  $sortBy: String\n  $sortDirection: String\n) {\n  ...NewsItemList_query_3VLBUE\n  ...NewsItemFilters_query\n}\n\nfragment NewsItemComponent_newsItem on NewsItem {\n  databaseId\n  publishedAt\n  title\n}\n\nfragment NewsItemFilters_query on Query {\n  businessUnits {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment NewsItemList_query_3VLBUE on Query {\n  newsItemPagination(beginPublishedAt: $beginPublishedAt, businessUnitIds: $businessUnitIds, endPublishedAt: $endPublishedAt, limit: $limit, page: $page, query: $query, sortBy: $sortBy, sortDirection: $sortDirection) {\n    newsItems {\n      ...NewsItemComponent_newsItem\n      id\n    }\n    totalCount\n  }\n}\n"
  }
};
})();

node.hash = "fad6a76d71b488f6b08f4f7fd595cff2";

module.exports = node;
