/**
 * @generated SignedSource<<cff87445cc88d5f2a8b1916f7e5e91b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessUnit",
  "kind": "LinkedField",
  "name": "businessUnits",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessUnitId",
      "storageKey": null
    },
    (v1/*: any*/),
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessUnitsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BusinessUnitsDiagram_organisation"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BusinessUnitModal_organisation"
          },
          (v0/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BusinessUnitsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c174bf0c902d9471e8f64a7f20c362c",
    "id": null,
    "metadata": {},
    "name": "BusinessUnitsQuery",
    "operationKind": "query",
    "text": "query BusinessUnitsQuery {\n  organisations {\n    ...BusinessUnitsDiagram_organisation\n    ...BusinessUnitModal_organisation\n    id\n    businessUnits {\n      businessUnitId\n      databaseId\n      id\n      name\n      number\n    }\n  }\n}\n\nfragment BusinessUnitModal_organisation on Organisation {\n  databaseId\n  id\n}\n\nfragment BusinessUnitsDiagram_organisation on Organisation {\n  ...BusinessUnitModal_organisation\n}\n"
  }
};
})();

node.hash = "8764cf64abe58cf64cff2ed7a5cd3a9a";

module.exports = node;
