/**
 * @generated SignedSource<<9f5b1a56c2e5df3e05e056e1597f39cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerFieldName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionFields",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfAssessmentResultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessment",
        "kind": "LinkedField",
        "name": "selfAssessment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SaQuestion",
            "kind": "LinkedField",
            "name": "saQuestions",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SelfAssessmentResult_saQuestion"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelfAssessmentResult_selfAssessment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfAssessmentResultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessment",
        "kind": "LinkedField",
        "name": "selfAssessment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SaQuestion",
            "kind": "LinkedField",
            "name": "saQuestions",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "complianceAnswer",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Deviation",
                "kind": "LinkedField",
                "name": "deviation",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "tasks",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SaAnswer",
                "kind": "LinkedField",
                "name": "saAnswers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "score",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationId",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "311c9e3de99a4544bf075bee786bb42b",
    "id": null,
    "metadata": {},
    "name": "SelfAssessmentResultsQuery",
    "operationKind": "query",
    "text": "query SelfAssessmentResultsQuery(\n  $id: ID!\n) {\n  selfAssessment(id: $id) {\n    answerFieldName\n    databaseId\n    name\n    saQuestions {\n      id\n      questionFields\n      ...SelfAssessmentResult_saQuestion\n    }\n    ...SelfAssessmentResult_selfAssessment\n    id\n  }\n}\n\nfragment SelfAssessmentResult_saQuestion on SaQuestion {\n  complianceAnswer\n  databaseId\n  id\n  questionFields\n  deviation {\n    databaseId\n    id\n    name\n    tasks {\n      databaseId\n      name\n      id\n    }\n  }\n  saAnswers {\n    answer\n    id\n    score\n  }\n}\n\nfragment SelfAssessmentResult_selfAssessment on SelfAssessment {\n  organisationId\n}\n"
  }
};
})();

node.hash = "e23ec9a7aa5afbc67eeda86f2c497608";

module.exports = node;
