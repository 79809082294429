/**
 * @generated SignedSource<<3d1af366d5e86e1563e3edbb7e52f6d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewNormRequirementQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NormRequirementFormFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewNormRequirementQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Norm",
        "kind": "LinkedField",
        "name": "norms",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NormParagraph",
            "kind": "LinkedField",
            "name": "normParagraphs",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c3b7e9164044152e46e5bf2a7f9820d",
    "id": null,
    "metadata": {},
    "name": "NewNormRequirementQuery",
    "operationKind": "query",
    "text": "query NewNormRequirementQuery {\n  ...NormRequirementFormFragment_query\n}\n\nfragment NormRequirementFormFragment_query on Query {\n  ...NormSelector_query\n}\n\nfragment NormSelector_query on Query {\n  norms {\n    databaseId\n    name\n    normParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "acc83b9016d8cc940bf156307550453b";

module.exports = node;
