import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import createRelaySubscriptionHandler from 'graphql-ruby-client/subscriptions/createRelaySubscriptionHandler';
import { createConsumer } from '@rails/actioncable';

const subscriptionHandler = createRelaySubscriptionHandler({ cable: createConsumer() });

class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnAuthorizedError';
  }
}

export async function fetchQuery(operation, variables, controller) {
  const response = await fetch('/graphql', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
    signal: controller !== undefined ? controller.signal : undefined,
  });

  const json = await response.json();

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    const message = `Error fetching GraphQL query '${operation.name}' with variables '${JSON.stringify(
      variables
    )}': ${JSON.stringify(json.errors)}`;

    if (json.errors.some((e) => e.message.includes('hidden due to permissions'))) {
      throw new UnauthorizedError(message);
    } else {
      throw new Error(message);
    }
  }

  // Otherwise, return the full payload.
  return json;
}

const environment = new Environment({
  network: Network.create(fetchQuery, subscriptionHandler),
  store: new Store(new RecordSource(), {
    // This property tells Relay to not immediately clear its cache when the user
    // navigates around the app. Relay will hold onto the specified number of
    // query results, allowing the user to return to recently visited pages
    // and reusing cached data if its available/fresh.
    gcReleaseBufferSize: 10,
  }),
});

export default environment;
