import { ReactComponent as SmartManSys } from './smartmansys.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;

  #Rectangle-Dark-blue {
    animation: bounce 1.5s infinite;
    animation-delay: 0s;
  }
  #Rectangle-Blue {
    animation: bounce 1.5s infinite;
    animation-delay: 0.1s;
  }
  #Rectangle-Light-blue {
    animation: bounce 1.5s infinite;
    animation-delay: 0.2s;
  }
  #Rectangle-Gray {
    animation: bounce 1.5s infinite;
    animation-delay: 0.3s;
  }

  @keyframes bounce {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(0, 2rem);
    }
    75% {
      transform: translate(0, 0);
    }
  }
`;

const Loading = () => {
  return (
    <Wrapper>
      <SmartManSys width={100} height={40} />
    </Wrapper>
  );
};

export default Loading;
