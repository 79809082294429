import { loadQuery } from 'react-relay/hooks';
import JSResource from './routing/js-resource';
import RelayEnvironment from './RelayEnvironment';

const manualParagraphRoutes = (namespace) => [
  {
    path: `/${namespace}/search`,
    exact: true,
    component: JSResource('ManualSearch', () => import('./Components/ManualSearch/ManualSearch')),
    prepare: () => {
      const ManualSearchQuery = require('./Components/ManualSearch/__generated__/ManualSearchQuery.graphql');
      const query = new URLSearchParams(window.location.search).get('q');
      const storageKey = `${namespace}-search`;
      const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
        limitDocument: 25,
        limitTextBlock: 25,
        pageDocument: 1,
        pageTextBlock: 1,
        sortBy: 'manual_paragraph',
        sortDirection: 'asc',
      };

      filters.query = query;

      return {
        filters,
        manualSearchQuery: loadQuery(RelayEnvironment, ManualSearchQuery, filters),
        storageKey,
      };
    },
  },
  {
    path: `/${namespace}/:id`,
    exact: true,
    component: JSResource('ManualParagraphContainer', () =>
      import('./Components/ManualParagraph/ManualParagraphContainer')
    ),
    prepare: ({ id }) => {
      const ManualParagraphQuery = require('./Components/ManualParagraph/__generated__/ManualParagraphContainerQuery.graphql');
      const storageKey = 'manuals';
      const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
        businessUnitIds: [],
      };
      return {
        filters,
        manualParagraphQuery: loadQuery(RelayEnvironment, ManualParagraphQuery, { id, ...filters }),
        storageKey,
      };
    },
  },
  {
    path: `/${namespace}/:manualParagraphId/text-blocks/:id`,
    exact: true,
    component: JSResource('TextBlock', () => import('./Components/TextBlock/TextBlock')),
    prepare: (params) => {
      const TextBlockQuery = require('./Components/TextBlock/__generated__/TextBlockQuery.graphql');
      return {
        textBlockQuery: loadQuery(RelayEnvironment, TextBlockQuery, {
          id: params.id,
          deviationId: '',
          loadDeviation: false,
          loadManualParagraph: true,
          loadTask: false,
          manualParagraphId: params.manualParagraphId,
          taskId: '',
        }),
      };
    },
  },
  {
    path: `/${namespace}/:manualParagraphId/text-blocks/:textBlockId/tables/new`,
    exact: true,
    component: JSResource('Table', () => import('./Components/Table/Table')),
    prepare: (params) => {
      const TableQuery = require('./Components/Table/__generated__/TableQuery.graphql');
      return {
        tableQuery: loadQuery(RelayEnvironment, TableQuery, {
          loadTable: false,
          manualParagraphId: params.manualParagraphId,
          tableId: '',
          textBlockId: params.textBlockId,
        }),
      };
    },
  },
  {
    path: `/${namespace}/:manualParagraphId/text-blocks/:textBlockId/tables/:id`,
    exact: true,
    component: JSResource('Table', () => import('./Components/Table/Table')),
    prepare: (params) => {
      const TableQuery = require('./Components/Table/__generated__/TableQuery.graphql');
      return {
        tableQuery: loadQuery(RelayEnvironment, TableQuery, {
          loadTable: true,
          manualParagraphId: params.manualParagraphId,
          tableId: params.id,
          textBlockId: params.textBlockId,
        }),
      };
    },
  },
];

const routes = [
  {
    path: '/404',
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    prepare: () => ({}),
    routes: [
      {
        path: '/404',
        component: JSResource('NotFound', () => import('./Components/NotFound/NotFound')),
        prepare: () => ({}),
      },
    ],
  },
  {
    component: JSResource('AnonymousDeviation', () => import('./Components/AnonymousDeviation/AnonymousDeviation')),
    path: '/anonymous/deviations/:id',
    exact: true,
    prepare: (params) => {
      const AnonymousDeviationQuery = require('./Components/AnonymousDeviation/__generated__/AnonymousDeviationQuery.graphql');
      return {
        anonymousDeviationQuery: loadQuery(RelayEnvironment, AnonymousDeviationQuery, {
          uuid: params.id,
        }),
      };
    },
  },
  {
    component: JSResource('AnonymousDeviationForm', () =>
      import('./Components/AnonymousDeviation/AnonymousDeviationForm')
    ),
    path: '/anonymous/deviations/:type/:id',
    exact: true,
    prepare: (params) => {
      const AnonymousDeviationFormQuery = require('./Components/AnonymousDeviation/__generated__/AnonymousDeviationFormQuery.graphql');
      return {
        anonymousDeviationFormQuery: loadQuery(RelayEnvironment, AnonymousDeviationFormQuery, {
          uuid: params.id,
          deviationType: params.type,
        }),
      };
    },
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/sessions',
    prepare: () => ({}),
    routes: [
      {
        path: '/sessions/error',
        exact: true,
        component: JSResource('Error', () => import('./Components/Session/Error')),
        prepare: () => ({}),
      },
      {
        path: '/sessions/new',
        exact: true,
        component: JSResource('Session', () => import('./Components/Session/LoginContainer')),
        prepare: () => {
          const LoginContainerQuery = require('./Components/Session/__generated__/LoginContainerQuery.graphql');
          return {
            loginContainerQuery: loadQuery(RelayEnvironment, LoginContainerQuery),
          };
        },
      },
      {
        path: '/sessions/organisation-selector',
        exact: true,
        component: JSResource('OrganisationSelector', () =>
          import('./Components/Session/OrganisationSelectorContainer')
        ),
        prepare: () => {
          const OrganisationSelectorContainerQuery = require('./Components/Session/__generated__/OrganisationSelectorContainerQuery.graphql');
          return {
            organisationSelectorContainerQuery: loadQuery(RelayEnvironment, OrganisationSelectorContainerQuery),
          };
        },
      },
      {
        path: '/sessions/redirect',
        exact: true,
        component: JSResource('Redirect', () => import('./Components/Session/Redirect')),
        prepare: () => ({}),
      },
      {
        path: '/sessions/unlock/:token',
        exact: true,
        component: JSResource('UnlockUser', () => import('./Components/UnlockUser/UnlockUser')),
        prepare: () => ({}),
      },
      {
        path: '/sessions/logout',
        exact: true,
        component: JSResource('Logout', () => import('./Components/Logout/Logout')),
        prepare: () => ({}),
      },
      {
        path: '/sessions/terms',
        exact: true,
        component: JSResource('AcceptTerms', () => import('./Components/Session/AcceptTerms')),
        prepare: () => {
          const AcceptTermsQuery = require('./Components/Session/__generated__/AcceptTermsQuery.graphql');
          return {
            acceptTermsQuery: loadQuery(RelayEnvironment, AcceptTermsQuery),
          };
        },
      },
    ],
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/invitations',
    prepare: () => ({}),
    routes: [
      {
        path: '/invitations/:token',
        exact: true,
        component: JSResource('invitation', () => import('./Components/Invitation/Invitation')),
        prepare: ({ token }) => {
          const InvitationQuery = require('./Components/Invitation/__generated__/InvitationQuery.graphql');
          return {
            invitationQuery: loadQuery(RelayEnvironment, InvitationQuery, { token }),
          };
        },
      },
    ],
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/password-reset',
    prepare: () => ({}),
    routes: [
      {
        path: '/password-reset/new',
        exact: true,
        component: JSResource('PasswordNew', () => import('./Components/PasswordReset/PasswordResetNew')),
        prepare: () => ({}),
      },
      {
        path: '/password-reset/:token',
        exact: true,
        component: JSResource('PasswordEdit', () => import('./Components/PasswordReset/PasswordResetEdit')),
        prepare: (params) => {
          const PasswordResetEditQuery = require('./Components/PasswordReset/__generated__/PasswordResetEditQuery.graphql');
          return {
            passwordResetEditQuery: loadQuery(RelayEnvironment, PasswordResetEditQuery, { token: params.token }),
          };
        },
      },
    ],
  },
  {
    component: JSResource('SessionLayout', () => import('./SessionLayout')),
    path: '/totp-reset',
    prepare: () => ({}),
    routes: [
      {
        path: '/totp-reset/new',
        exact: true,
        component: JSResource('ResetTotpNew', () => import('./Components/ResetTotp/ResetTotpNew')),
        prepare: () => ({}),
      },
      {
        path: '/totp-reset/:token',
        exact: true,
        component: JSResource('ResetTotpEdit', () => import('./Components/ResetTotp/ResetTotpEdit')),
        prepare: () => ({}),
      },
    ],
  },
  {
    path: '/',
    component: JSResource('App', () => import('./App')),
    prepare: () => {
      const AppQuery = require('./__generated__/AppQuery.graphql');
      return {
        appQuery: loadQuery(RelayEnvironment, AppQuery),
      };
    },
    routes: [
      {
        path: '/',
        exact: true,
        component: JSResource('Dashboard', () => import('./Components/Dashboard/Dashboard')),
        prepare: () => {
          const DashboardQuery = require('./Components/Dashboard/__generated__/DashboardQuery.graphql');
          return {
            dashboardQuery: loadQuery(RelayEnvironment, DashboardQuery),
          };
        },
      },
      ...manualParagraphRoutes('manual-paragraphs'),
      ...manualParagraphRoutes('product-paragraphs'),
      {
        path: '/audit-questionnaires/:id',
        exact: true,
        component: JSResource('AuditQuestionnaire', () => import('./Components/AuditQuestionnaire/AuditQuestionnaire')),
        prepare: (params) => {
          const AuditQuestionnaireQuery = require('./Components/AuditQuestionnaire/__generated__/AuditQuestionnaireQuery.graphql.js');
          return {
            auditQuestionnaireQuery: loadQuery(RelayEnvironment, AuditQuestionnaireQuery, { id: params.id }),
          };
        },
      },
      {
        path: '/audits',
        exact: true,
        component: JSResource('Audits', () => import('./Components/Audits/Audits')),
        prepare: () => {
          const AuditsQuery = require('./Components/Audits/__generated__/AuditsQuery.graphql');
          const storageKey = 'audits-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            limit: 25,
            page: 1,
            query: '',
            sortBy: 'id',
            sortDirection: 'desc',
          };
          return {
            auditsQuery: loadQuery(RelayEnvironment, AuditsQuery, filters, { fetchPolicy: 'network-only' }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/audits/new',
        exact: true,
        component: JSResource('NewAudit', () => import('./Components/NewAudit/NewAudit')),
        prepare: () => {
          const NewAuditQuery = require('./Components/NewAudit/__generated__/NewAuditQuery.graphql');
          return {
            newAuditQuery: loadQuery(RelayEnvironment, NewAuditQuery),
          };
        },
      },
      {
        path: '/audits/:id',
        exact: true,
        component: JSResource('AuditAgenda', () => import('./Components/AuditAgenda/AuditAgenda')),
        prepare: ({ id }) => {
          const AuditAgendaQuery = require('./Components/AuditAgenda/__generated__/AuditAgendaQuery.graphql.js');
          const storageKey = `audit-participants-filter-${id}`;
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            query: '',
            sortBy: 'start_date',
            sortDirection: 'asc',
          };
          return {
            auditAgendaQuery: loadQuery(RelayEnvironment, AuditAgendaQuery, { ...filters, id }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/audits/:id/findings',
        exact: true,
        component: JSResource('AuditFindingsClusters', () =>
          import('./Components/AuditFindingsClusters/AuditFindingsClusters')
        ),
        prepare: ({ id }) => {
          const AuditFindingsClustersQuery = require('./Components/AuditFindingsClusters/__generated__/AuditFindingsClustersQuery.graphql.js');
          return {
            auditFindingsClustersQuery: loadQuery(RelayEnvironment, AuditFindingsClustersQuery, { id }),
          };
        },
      },
      {
        path: '/audits/:auditId/findings/new',
        exact: true,
        component: JSResource('NewAuditFindingsCluster', () =>
          import('./Components/NewAuditFindingsCluster/NewAuditFindingsCluster')
        ),
        prepare: ({ auditId }) => {
          const NewAuditFindingsClusterQuery = require('./Components/NewAuditFindingsCluster/__generated__/NewAuditFindingsClusterQuery.graphql.js');
          return {
            newAuditFindingsClusterQuery: loadQuery(RelayEnvironment, NewAuditFindingsClusterQuery, { auditId }),
          };
        },
      },
      {
        path: '/audits/:auditId/findings/:id',
        exact: true,
        component: JSResource('AuditFindingsCluster', () =>
          import('./Components/AuditFindingsCluster/AuditFindingsCluster')
        ),
        prepare: ({ auditId, id }) => {
          const AuditFindingsClusterQuery = require('./Components/AuditFindingsCluster/__generated__/AuditFindingsClusterQuery.graphql.js');
          return {
            auditFindingsClusterQuery: loadQuery(RelayEnvironment, AuditFindingsClusterQuery, { auditId, id }),
          };
        },
      },
      {
        path: '/audits/:id/invite',
        exact: true,
        component: JSResource('AuditAgendaInvite', () => import('./Components/AuditAgenda/AuditAgendaInvite')),
        prepare: (params) => {
          const AuditAgendaInviteQuery = require('./Components/AuditAgenda/__generated__/AuditAgendaInviteQuery.graphql');
          return {
            auditAgendaInviteQuery: loadQuery(
              RelayEnvironment,
              AuditAgendaInviteQuery,
              {
                id: params.id,
                organisationId: null,
              },
              { fetchPolicy: 'network-only' }
            ),
          };
        },
      },
      {
        path: '/audits/:id/plan',
        exact: true,
        component: JSResource('AuditPlan', () => import('./Components/AuditPlan/AuditPlan')),
        prepare: (params) => {
          const AuditPlanQuery = require('./Components/AuditPlan/__generated__/AuditPlanQuery.graphql.js');
          return {
            auditPlanQuery: loadQuery(
              RelayEnvironment,
              AuditPlanQuery,
              { id: params.id },
              { fetchPolicy: 'network-only' }
            ),
          };
        },
      },
      {
        path: '/deviations',
        exact: true,
        component: JSResource('Deviations', () => import('./Components/Deviations/Deviations')),
        prepare: () => {
          const DeviationsQuery = require('./Components/Deviations/__generated__/DeviationsQuery.graphql');
          const storageKey = 'deviations-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            assignedToIds: [],
            beginCreatedAt: '',
            businessUnitIds: [],
            createdByIds: [],
            damageAmountIds: [],
            deviationCategoryIds: [],
            deviationTypes: [],
            endCreatedAt: '',
            focusAreas: [],
            limit: 25,
            manualParagraphIds: [],
            normParagraphIds: [],
            organisationIds: [],
            page: 1,
            priorities: [],
            query: '',
            secondDeviationCategoryIds: [],
            sortBy: 'id',
            sortDirection: 'desc',
            statuses: ['new', 'in_progress', 'waiting', 'rejected', 'overdue'],
            thirdDeviationCategoryIds: [],
          };
          return {
            deviationsQuery: loadQuery(RelayEnvironment, DeviationsQuery, filters, { fetchPolicy: 'network-only' }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/deviations/new',
        exact: true,
        component: JSResource('NewDeviation', () => import('./Components/NewDeviation/NewDeviation')),
        prepare: () => {
          const NewDeviationLocalQuery = require('./Components/NewDeviation/__generated__/NewDeviationLocalQuery.graphql');
          const NewDeviationQuery = require('./Components/NewDeviation/__generated__/NewDeviationQuery.graphql');
          return {
            newDeviationLocalQuery: loadQuery(
              RelayEnvironment,
              NewDeviationLocalQuery,
              { id: 'client:Deviation' },
              { fetchPolicy: 'store-only' }
            ),
            newDeviationQuery: loadQuery(RelayEnvironment, NewDeviationQuery),
          };
        },
      },
      {
        path: '/deviations/:id',
        exact: true,
        component: JSResource('Deviation', () => import('./Components/Deviation/Deviation')),
        prepare: (params) => {
          const DeviationQuery = require('./Components/Deviation/__generated__/DeviationQuery.graphql');
          return {
            deviationQuery: loadQuery(RelayEnvironment, DeviationQuery, { id: params.id }),
          };
        },
      },
      {
        path: '/deviations/:deviationId/tasks/:id',
        exact: true,
        component: JSResource('Task', () => import('./Components/Task/Task')),
        prepare: (params) => {
          const TaskQuery = require('./Components/Task/__generated__/TaskQuery.graphql');
          return {
            taskQuery: loadQuery(RelayEnvironment, TaskQuery, {
              id: params.id,
              deviationId: params.deviationId,
              loadDeviation: true,
            }),
          };
        },
      },
      {
        path: '/deviations/:deviationId/tasks/:taskId/documents/:id',
        exact: true,
        component: JSResource('Document', () => import('./Components/Document/Document')),
        prepare: (params) => {
          const DocumentQuery = require('./Components/Document/__generated__/DocumentQuery.graphql');
          return {
            documentQuery: loadQuery(RelayEnvironment, DocumentQuery, {
              id: params.id,
              loadDeviation: true,
              loadTask: true,
              deviationId: params.deviationId,
              taskId: params.taskId,
            }),
          };
        },
      },
      {
        path: '/deviations/:deviationId/tasks/:taskId/text-blocks/:id',
        exact: true,
        component: JSResource('TextBlock', () => import('./Components/TextBlock/TextBlock')),
        prepare: (params) => {
          const TextBlockQuery = require('./Components/TextBlock/__generated__/TextBlockQuery.graphql');
          return {
            textBlockQuery: loadQuery(RelayEnvironment, TextBlockQuery, {
              id: params.id,
              deviationId: params.deviationId,
              loadDeviation: true,
              loadManualParagraph: false,
              loadTask: true,
              manualParagraphId: '',
              taskId: params.taskId,
            }),
          };
        },
      },
      {
        path: '/documents',
        exact: true,
        component: JSResource('Documents', () => import('./Components/Documents/Documents')),
        prepare: () => {
          const DocumentsQuery = require('./Components/Documents/__generated__/DocumentsQuery.graphql');
          const storageKey = 'documents-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            businessUnitIds: [],
            classifications: [],
            deleted: false,
            limit: 25,
            manualParagraphIds: [],
            organisationIds: [],
            page: 1,
            query: '',
            sortBy: 'id',
            sortDirection: 'desc',
            statuses: [],
            userIds: [],
          };
          return {
            documentsQuery: loadQuery(RelayEnvironment, DocumentsQuery, filters, { fetchPolicy: 'network-only' }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/documents/new',
        exact: true,
        component: JSResource('NewDocument', () => import('./Components/NewDocument/NewDocument')),
        prepare: () => {
          const newDocumentQuery = require('./Components/NewDocument/__generated__/NewDocumentQuery.graphql');
          return {
            newDocumentQuery: loadQuery(RelayEnvironment, newDocumentQuery),
          };
        },
      },
      {
        path: '/documents/:id',
        exact: true,
        component: JSResource('Document', () => import('./Components/Document/Document')),
        prepare: (params) => {
          const DocumentQuery = require('./Components/Document/__generated__/DocumentQuery.graphql');
          return {
            documentQuery: loadQuery(RelayEnvironment, DocumentQuery, {
              id: params.id,
              loadDeviation: false,
              loadTask: false,
              deviationId: '',
              taskId: '',
            }),
          };
        },
      },
      {
        path: '/dpia-questionnaires',
        exact: true,
        component: JSResource('DpiaQuestionnaires', () => import('./Components/DpiaQuestionnaires/DpiaQuestionnaires')),
        prepare: () => {
          const DpiaQuestionnairesQuery = require('./Components/DpiaQuestionnaires/__generated__/DpiaQuestionnairesQuery.graphql');
          const storageKey = 'dpiaQuestionnaires-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            limit: 25,
            page: 1,
            sortBy: 'id',
            sortDirection: 'desc',
          };
          return {
            dpiaQuestionnairesQuery: loadQuery(RelayEnvironment, DpiaQuestionnairesQuery, filters, {
              fetchPolicy: 'network-only',
            }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/dpia-questionnaires/new',
        exact: true,
        component: JSResource('NewDpiaQuestionnaire', () =>
          import('./Components/NewDpiaQuestionnaire/NewDpiaQuestionnaire')
        ),
        prepare: () => {
          const NewDpiaQuestionnaireQuery = require('./Components/NewDpiaQuestionnaire/__generated__/NewDpiaQuestionnaireQuery.graphql');
          return {
            newDpiaQuestionnaireQuery: loadQuery(RelayEnvironment, NewDpiaQuestionnaireQuery),
          };
        },
      },
      {
        path: '/dpia-questionnaires/:id',
        exact: true,
        component: JSResource('DpiaQuestionnaire', () => import('./Components/DpiaQuestionnaire/DpiaQuestionnaire')),
        prepare: (params) => {
          const DpiaQuestionnaireQuery = require('./Components/DpiaQuestionnaire/__generated__/DpiaQuestionnaireQuery.graphql');
          return {
            dpiaQuestionnaireQuery: loadQuery(RelayEnvironment, DpiaQuestionnaireQuery, {
              id: params.id,
              loadDeviation: false,
              loadTask: false,
              deviationId: '',
              taskId: '',
            }),
          };
        },
      },
      {
        path: '/organisation/information',
        exact: true,
        component: JSResource('InformationSources', () => import('./Components/InformationSources/InformationSources')),
        prepare: () => {
          const InformationSourcesQuery = require('./Components/InformationSources/__generated__/InformationSourcesOverviewQuery.graphql');
          const storageKey = 'information-sources-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            jobTitleIds: [],
            limit: 25,
            manualParagraphIds: [],
            organisationIds: [],
            page: 1,
            query: '',
            sortBy: 'id',
            sortDirection: 'desc',
          };
          return {
            filters,
            informationSourcesQuery: loadQuery(RelayEnvironment, InformationSourcesQuery, filters, {
              fetchPolicy: 'network-only',
            }),
            storageKey,
          };
        },
      },
      {
        path: '/organisation/information/new',
        exact: true,
        component: JSResource('NewInformationSource', () =>
          import('./Components/NewInformationSource/NewInformationSource')
        ),
        prepare: () => {
          const InformationSourceLocalQuery = require('./Components/NewInformationSource/__generated__/NewInformationSourceLocalQuery.graphql');
          const InformationSourceQuery = require('./Components/NewInformationSource/__generated__/NewInformationSourceQuery.graphql');
          return {
            informationSourceLocalQuery: loadQuery(
              RelayEnvironment,
              InformationSourceLocalQuery,
              { id: 'client:InformationSource' },
              { fetchPolicy: 'store-only' }
            ),
            informationSourceQuery: loadQuery(RelayEnvironment, InformationSourceQuery),
          };
        },
      },
      {
        path: '/organisation/information/:id',
        exact: true,
        component: JSResource('InformationSource', () => import('./Components/InformationSource/InformationSource')),
        prepare: (params) => {
          const InformationSourceQuery = require('./Components/InformationSource/__generated__/InformationSourceQuery.graphql');
          return {
            informationSourceQuery: loadQuery(RelayEnvironment, InformationSourceQuery, {
              id: params.id,
              ippaId: '',
              loadIppa: false,
            }),
          };
        },
      },
      {
        path: '/organisation/job-titles',
        exact: true,
        component: JSResource('JobTitles', () => import('./Components/JobTitles/JobTitles')),
        prepare: () => {
          const JobTitlesQuery = require('./Components/JobTitles/__generated__/JobTitlesQuery.graphql');
          const storageKey = 'jobtitles-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            hasCriticalAvailability: false,
            hasCriticalConfidentiality: false,
            hasCriticalIntegrity: false,
            limit: 25,
            organisationIds: [],
            page: 1,
            query: '',
            sortBy: 'id',
            sortDirection: 'desc',
          };
          return {
            filters,
            jobTitlesQuery: loadQuery(RelayEnvironment, JobTitlesQuery, filters, { fetchPolicy: 'network-only' }),
            storageKey,
          };
        },
      },
      {
        path: '/organisation/job-titles/new',
        exact: true,
        component: JSResource('NewJobTitle', () => import('./Components/NewJobTitle/NewJobTitle')),
        prepare: ({ id }) => {
          const NewJobTitleLocalQuery = require('./Components/NewJobTitle/__generated__/NewJobTitleLocalQuery.graphql');
          const NewJobTitleQuery = require('./Components/NewJobTitle/__generated__/NewJobTitleQuery.graphql');
          return {
            newJobTitleLocalQuery: loadQuery(
              RelayEnvironment,
              NewJobTitleLocalQuery,
              { id: 'client:JobTitle' },
              { fetchPolicy: 'store-only' }
            ),
            newJobTitleQuery: loadQuery(RelayEnvironment, NewJobTitleQuery),
          };
        },
      },
      {
        path: '/organisation/job-titles/:id',
        exact: true,
        component: JSResource('JobTitle', () => import('./Components/JobTitle/JobTitle')),
        prepare: ({ id }) => {
          const JobTitleQuery = require('./Components/JobTitle/__generated__/JobTitleQuery.graphql');
          return {
            jobTitleQuery: loadQuery(RelayEnvironment, JobTitleQuery, { id }),
          };
        },
      },
      {
        path: '/organisation/ippa/:id',
        exact: true,
        component: JSResource('IPPA', () => import('./Components/IPPA/IPPA')),
        prepare: (params) => {
          const IPPAQuery = require('./Components/IPPA/__generated__/IPPAQuery.graphql');
          return {
            IPPAQuery: loadQuery(RelayEnvironment, IPPAQuery, params),
          };
        },
      },
      {
        path: '/organisation/ippa/:ippaId/information/:id',
        exact: true,
        component: JSResource('InformationSource', () => import('./Components/InformationSource/InformationSource')),
        prepare: (params) => {
          const InformationSourceQuery = require('./Components/InformationSource/__generated__/InformationSourceQuery.graphql');
          return {
            informationSourceQuery: loadQuery(RelayEnvironment, InformationSourceQuery, {
              id: params.id,
              ippaId: params.ippaId,
              loadIppa: true,
            }),
          };
        },
      },
      {
        path: '/organisation/ippa/:ippaId/processes/:id',
        exact: true,
        component: JSResource('ManualParagraph', () => import('./Components/Process/ManualParagraph')),
        prepare: (params) => {
          const ManualParagraphQuery = require('./Components/Process/__generated__/ManualParagraphQuery.graphql');
          return {
            manualParagraphQuery: loadQuery(RelayEnvironment, ManualParagraphQuery, {
              id: params.id,
              ippaId: params.ippaId,
              loadIppa: true,
            }),
          };
        },
      },
      {
        path: '/organisation/ippa/:ippaId/resources/:id',
        exact: true,
        component: JSResource('Resource', () => import('./Components/Resource/Resource')),
        prepare: (params) => {
          const ResourceQuery = require('./Components/Resource/__generated__/ResourceQuery.graphql');
          return {
            resourceQuery: loadQuery(RelayEnvironment, ResourceQuery, {
              id: params.id,
              ippaId: params.ippaId,
              loadIppa: true,
            }),
          };
        },
      },
      {
        path: '/organisation/resources',
        exact: true,
        component: JSResource('Resources', () => import('./Components/Resources/Resources')),
        prepare: () => {
          const ResourcesQuery = require('./Components/Resources/__generated__/ResourcesQuery.graphql');
          const storageKey = 'resources-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            hasCriticalAvailability: false,
            hasCriticalConfidentiality: false,
            hasCriticalIntegrity: false,
            jobTitleIds: [],
            limit: 25,
            manualParagraphIds: [],
            manualsResourceCategoryIds: [],
            organisationIds: [],
            page: 1,
            query: '',
            sortBy: 'id',
            sortDirection: 'desc',
            supplierIds: [],
          };
          return {
            filters,
            resourcesQuery: loadQuery(RelayEnvironment, ResourcesQuery, filters, { fetchPolicy: 'network-only' }),
            storageKey,
          };
        },
      },
      {
        path: '/organisation/resources/new',
        exact: true,
        component: JSResource('NewResource', () => import('./Components/NewResource/NewResource')),
        prepare: () => {
          const NewResourceLocalQuery = require('./Components/NewResource/__generated__/NewResourceLocalQuery.graphql');
          const NewResourceQuery = require('./Components/NewResource/__generated__/NewResourceQuery.graphql');
          return {
            newResourceLocalQuery: loadQuery(
              RelayEnvironment,
              NewResourceLocalQuery,
              { id: 'client:Resource' },
              { fetchPolicy: 'store-only' }
            ),
            newResourceQuery: loadQuery(RelayEnvironment, NewResourceQuery),
          };
        },
      },
      {
        path: '/organisation/resources/:id',
        exact: true,
        component: JSResource('Resource', () => import('./Components/Resource/Resource')),
        prepare: (params) => {
          const ResourceQuery = require('./Components/Resource/__generated__/ResourceQuery.graphql');
          return {
            resourceQuery: loadQuery(RelayEnvironment, ResourceQuery, { id: params.id, ippaId: '', loadIppa: false }),
          };
        },
      },
      {
        path: '/organisation/suppliers',
        exact: true,
        component: JSResource('Suppliers', () => import('./Components/Suppliers/Suppliers')),
        prepare: () => {
          const SupplierQuery = require('./Components/Suppliers/__generated__/SuppliersOverviewQuery.graphql');
          const storageKey = 'suppliers-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            hasCriticalAvailability: false,
            hasCriticalConfidentiality: false,
            hasCriticalIntegrity: false,
            jobTitleIds: [],
            limit: 25,
            organisationIds: [],
            page: 1,
            query: '',
            resourceIds: [],
            sortBy: 'id',
            sortDirection: 'asc',
          };
          return {
            filters,
            storageKey,
            suppliersQuery: loadQuery(RelayEnvironment, SupplierQuery, filters, { fetchPolicy: 'network-only' }),
          };
        },
      },
      {
        path: '/organisation/suppliers/new',
        exact: true,
        component: JSResource('NewSupplier', () => import('./Components/NewSupplier/NewSupplier')),
        prepare: () => {
          const SupplierLocalQuery = require('./Components/NewSupplier/__generated__/NewSupplierLocalQuery.graphql');
          const SupplierQuery = require('./Components/NewSupplier/__generated__/NewSupplierQuery.graphql');
          return {
            supplierLocalQuery: loadQuery(RelayEnvironment, SupplierLocalQuery, { id: 'client:Supplier' }),
            supplierQuery: loadQuery(RelayEnvironment, SupplierQuery),
          };
        },
      },
      {
        path: '/organisation/suppliers/:id',
        exact: true,
        component: JSResource('Supplier', () => import('./Components/Supplier/Supplier')),
        prepare: (params) => {
          const SupplierQuery = require('./Components/Supplier/__generated__/SupplierQuery.graphql');
          return {
            supplierQuery: loadQuery(RelayEnvironment, SupplierQuery, params),
          };
        },
      },

      {
        path: '/organisation/processes',
        exact: true,
        component: JSResource('Processes', () => import('./Components/Processes/ManualParagraphs')),

        prepare: () => {
          const ManualParagraphQuery = require('./Components/Processes/__generated__/ManualParagraphsOverviewQuery.graphql');
          const storageKey = 'manual-paragraphs-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            hasCriticalAvailability: false,
            hasCriticalConfidentiality: false,
            hasCriticalIntegrity: false,
            jobTitleIds: [],
            limit: 25,
            manualChapterId: NaN,
            organisationIds: [],
            page: 1,
            query: '',
            sortBy: 'number',
            sortDirection: 'asc',
          };
          return {
            filters,
            storageKey,
            manualParagraphsQuery: loadQuery(RelayEnvironment, ManualParagraphQuery, filters, {
              fetchPolicy: 'network-only',
            }),
          };
        },
      },
      {
        path: '/organisation/processes/:id',
        exact: true,
        component: JSResource('ManualParagraph', () => import('./Components/Process/ManualParagraph')),
        prepare: ({ id }) => {
          const ManualParagraphQuery = require('./Components/Process/__generated__/ManualParagraphQuery.graphql');
          return {
            manualParagraphQuery: loadQuery(RelayEnvironment, ManualParagraphQuery, {
              id,
              ippaId: '',
              loadIppa: false,
            }),
          };
        },
      },
      {
        path: '/risk-analysis/risks',
        exact: true,
        component: JSResource('Risks', () => import('./Components/Risks/Risks')),
        prepare: () => {
          const RisksQuery = require('./Components/Risks/__generated__/RisksQuery.graphql');
          const storageKey = 'risks-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            impactScore: 0,
            limit: 25,
            lowerResidualRiskScore: 0,
            lowerRiskScore: 0,
            organisationIds: [],
            page: 1,
            probabilityScore: 0,
            query: '',
            residualImpactScore: 0,
            residualProbabilityScore: 0,
            riskTypes: [],
            riskables: [],
            statuses: ['new', 'in_progress', 'accepted'],
            sortBy: 'id',
            sortDirection: 'asc',
            threatIds: [],
          };
          return {
            filters,
            storageKey,
            risksQuery: loadQuery(RelayEnvironment, RisksQuery, filters, { fetchPolicy: 'network-only' }),
          };
        },
      },
      {
        path: '/risk-analysis/risks/new',
        exact: true,
        component: JSResource('NewRisk', () => import('./Components/NewRisk/NewRisk')),
        prepare: () => {
          const NewRiskLocalQuery = require('./Components/NewRisk/__generated__/NewRiskLocalQuery.graphql');
          const NewRiskQuery = require('./Components/NewRisk/__generated__/NewRiskQuery.graphql');
          return {
            newRiskLocalQuery: loadQuery(
              RelayEnvironment,
              NewRiskLocalQuery,
              { id: 'client:Risk' },
              { fetchPolicy: 'store-only' }
            ),
            newRiskQuery: loadQuery(RelayEnvironment, NewRiskQuery),
          };
        },
      },
      {
        path: '/risk-analysis/threats',
        exact: true,
        component: JSResource('Threats', () => import('./Components/Threats/Threats')),
        prepare: () => {
          const ThreatsQuery = require('./Components/Threats/__generated__/ThreatsQuery.graphql');
          const storageKey = 'threats-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            limit: 25,
            organisationIds: [],
            page: 1,
            query: '',
            sortBy: 'id',
            sortDirection: 'asc',
          };
          return {
            filters,
            storageKey,
            threatsQuery: loadQuery(RelayEnvironment, ThreatsQuery, filters, { fetchPolicy: 'network-only' }),
          };
        },
      },
      {
        path: '/risk-analysis/threats/new',
        exact: true,
        component: JSResource('NewThreat', () => import('./Components/NewThreat/NewThreat')),
        prepare: () => {
          const NewThreatLocalQuery = require('./Components/NewThreat/__generated__/NewThreatLocalQuery.graphql');
          const NewThreatQuery = require('./Components/NewThreat/__generated__/NewThreatQuery.graphql');
          return {
            newThreatLocalQuery: loadQuery(
              RelayEnvironment,
              NewThreatLocalQuery,
              { id: 'client:Threat' },
              { fetchPolicy: 'store-only' }
            ),
            newThreatQuery: loadQuery(RelayEnvironment, NewThreatQuery),
          };
        },
      },
      {
        path: '/risk-analysis/threats/:id',
        exact: true,
        component: JSResource('Threat', () => import('./Components/Threat/Threat')),
        prepare: (params) => {
          const ThreatQuery = require('./Components/Threat/__generated__/ThreatQuery.graphql');
          return {
            threatQuery: loadQuery(RelayEnvironment, ThreatQuery, params),
          };
        },
      },
      {
        path: '/members',
        exact: true,
        component: JSResource('Members', () => import('./Components/Members/Members')),
        prepare: () => {
          const MembersQuery = require('./Components/Members/__generated__/MembersQuery.graphql');
          const storageKey = 'members-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            businessUnitIds: [],
            jobTitleIds: [],
            limit: 25,
            organisationIds: [],
            query: '',
            page: 1,
            roles: [],
            sortBy: 'name',
            sortDirection: 'asc',
            statuses: ['active'],
          };
          return {
            membersQuery: loadQuery(RelayEnvironment, MembersQuery, filters, { fetchPolicy: 'network-only' }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/members/new',
        exact: true,
        component: JSResource('NewMember', () => import('./Components/NewMember/NewMember')),
        prepare: () => {
          const NewMemberQuery = require('./Components/NewMember/__generated__/NewMemberQuery.graphql');
          return {
            newMemberQuery: loadQuery(RelayEnvironment, NewMemberQuery),
          };
        },
      },
      {
        path: '/members/import',
        exact: true,
        component: JSResource('ImportMembers', () => import('./Components/ImportMembers/ImportMembers')),
        prepare: () => {
          const ImportMembersQuery = require('./Components/ImportMembers/__generated__/ImportMembersQuery.graphql');
          return {
            importMembersQuery: loadQuery(RelayEnvironment, ImportMembersQuery),
          };
        },
      },
      {
        path: '/members/:id',
        exact: false,
        component: JSResource('MemberLayout', () => import('./Components/Member/MemberLayout')),
        prepare: (params) => {
          const MemberLayoutQuery = require('./Components/Member/__generated__/MemberLayoutQuery.graphql');
          return {
            memberLayoutQuery: loadQuery(RelayEnvironment, MemberLayoutQuery, { id: params.id }),
          };
        },
        routes: [
          {
            path: '/members/:id/info',
            exact: true,
            component: JSResource('MemberProfile', () => import('./Components/Member/MemberProfile')),
            prepare: (params) => {
              const MemberProfileQuery = require('./Components/Member/__generated__/MemberProfileQuery.graphql');
              return {
                memberProfileQuery: loadQuery(RelayEnvironment, MemberProfileQuery, { id: params.id }),
              };
            },
          },
          {
            path: '/members/:id/account',
            exact: true,
            component: JSResource('MemberAccount', () => import('./Components/Member/MemberAccount')),
            prepare: (params) => {
              const MemberAccountQuery = require('./Components/Member/__generated__/MemberAccountQuery.graphql');
              return {
                memberAccountQuery: loadQuery(RelayEnvironment, MemberAccountQuery, { id: params.id }),
              };
            },
          },
        ],
      },
      {
        path: '/news-items',
        exact: true,
        component: JSResource('NewsItems', () => import('./Components/NewsItems/NewsItems')),
        prepare: () => {
          const NewsItemsQuery = require('./Components/NewsItems/__generated__/NewsItemsQuery.graphql');
          const storageKey = 'newsItems-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            beginPublishedAt: '',
            businessUnitIds: [],
            endPublishedAt: '',
            limit: 25,
            page: 1,
            query: '',
            sortBy: 'published_at',
            sortDirection: 'desc',
          };
          return {
            newsItemsQuery: loadQuery(RelayEnvironment, NewsItemsQuery, filters, { fetchPolicy: 'network-only' }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/news-items/new',
        exact: true,
        component: JSResource('NewNewsItem', () => import('./Components/NewNewsItem/NewNewsItem')),
        prepare: () => ({}),
      },
      {
        path: '/news-items/:id',
        exact: true,
        component: JSResource('NewsItem', () => import('./Components/NewsItem/NewsItem')),
        prepare: (params) => {
          const NewsItemQuery = require('./Components/NewsItem/__generated__/NewsItemQuery.graphql');
          return {
            newsItemQuery: loadQuery(RelayEnvironment, NewsItemQuery, { id: params.id, first: 5 }),
          };
        },
      },
      {
        path: '/news-items/:id/edit',
        exact: true,
        component: JSResource('NewsItemEdit', () => import('./Components/NewsItem/NewsItemEdit')),
        prepare: (params) => {
          const NewsItemEditQuery = require('./Components/NewsItem/__generated__/NewsItemEditQuery.graphql');
          return {
            newsItemEditQuery: loadQuery(RelayEnvironment, NewsItemEditQuery, { id: params.id }),
          };
        },
      },
      {
        path: '/reference-tables/:id/norm',
        exact: true,
        component: JSResource('Norm', () => import('./Components/Norm/Norm')),
        prepare: ({ id }) => {
          const NormQuery = require('./Components/Norm/__generated__/NormQuery.graphql');
          return {
            normQuery: loadQuery(RelayEnvironment, NormQuery, { id }),
          };
        },
      },
      {
        path: '/processing-registers',
        exact: true,
        component: JSResource('ProcessingRegisters', () =>
          import('./Components/ProcessingRegisters/ProcessingRegisters')
        ),
        prepare: () => {
          const ProcessingRegistersQuery = require('./Components/ProcessingRegisters/__generated__/ProcessingRegistersQuery.graphql');
          const storageKey = 'processing-registers-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            businessUnitIds: [],
            compliant: [],
            lawfulnessOfProcessing: [],
            limit: 25,
            manualParagraphIds: [],
            organisationIds: [],
            page: 1,
            responsibleForProcessing: [],
            sortBy: 'id',
            sortDirection: 'desc',
          };
          return {
            filters,
            processingRegistersQuery: loadQuery(RelayEnvironment, ProcessingRegistersQuery, filters, {
              fetchPolicy: 'network-only',
            }),
            storageKey,
          };
        },
      },
      {
        path: '/processing-registers/new',
        exact: true,
        component: JSResource('NewProcessingRegister', () =>
          import('./Components/NewProcessingRegister/NewProcessingRegister')
        ),
        prepare: () => {
          const NewProcessingRegisterLocalQuery = require('./Components/NewProcessingRegister/__generated__/NewProcessingRegisterLocalQuery.graphql');
          const NewProcessingRegisterQuery = require('./Components/NewProcessingRegister/__generated__/NewProcessingRegisterQuery.graphql');
          return {
            newProcessingRegisterLocalQuery: loadQuery(
              RelayEnvironment,
              NewProcessingRegisterLocalQuery,
              { id: 'client:ProcessingRegister' },
              { fetchPolicy: 'store-only' }
            ),
            newProcessingRegisterQuery: loadQuery(RelayEnvironment, NewProcessingRegisterQuery),
          };
        },
      },
      {
        path: '/processing-registers/:id',
        exact: true,
        component: JSResource('ProcessingRegister', () => import('./Components/ProcessingRegister/ProcessingRegister')),
        prepare: (params) => {
          const ProcessingRegisterQuery = require('./Components/ProcessingRegister/__generated__/ProcessingRegisterQuery.graphql');
          return {
            processingRegisterQuery: loadQuery(RelayEnvironment, ProcessingRegisterQuery, { id: params.id }),
          };
        },
      },
      {
        path: '/processing-registers/:id/new',
        exact: true,
        component: JSResource('CopyProcessingRegister', () =>
          import('./Components/ProcessingRegister/CopyProcessingRegister.js')
        ),
        prepare: (params) => {
          const CopyProcessingRegisterQuery = require('./Components/ProcessingRegister/__generated__/CopyProcessingRegisterQuery.graphql');
          return {
            copyProcessingRegisterQuery: loadQuery(RelayEnvironment, CopyProcessingRegisterQuery, { id: params.id }),
          };
        },
      },
      {
        path: '/reference-tables/:id',
        exact: true,
        component: JSResource('NormOrganisation', () => import('./Components/ReferenceTables/NormOrganisation')),
        prepare: (params) => {
          const NormOrganisationQuery = require('./Components/ReferenceTables/__generated__/NormOrganisationQuery.graphql');
          return {
            normOrganisationQuery: loadQuery(RelayEnvironment, NormOrganisationQuery, { id: params.id }),
          };
        },
      },
      {
        path: '/self-assessments',
        exact: true,
        component: JSResource('SelfAssessments', () => import('./Components/SelfAssessments/SelfAssessments')),
        prepare: () => {
          const SelfAssessmentsQuery = require('./Components/SelfAssessments/__generated__/SelfAssessmentsQuery.graphql');
          const storageKey = 'selfAssessments-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            limit: 25,
            page: 1,
            sortBy: 'id',
            sortDirection: 'desc',
          };
          return {
            selfAssessmentsQuery: loadQuery(RelayEnvironment, SelfAssessmentsQuery, filters, {
              fetchPolicy: 'network-only',
            }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/self-assessments/new',
        exact: true,
        component: JSResource('NewSelfAssessment', () => import('./Components/NewSelfAssessment/NewSelfAssessment')),
        prepare: (params) => {
          const NewSelfAssessmentQuery = require('./Components/NewSelfAssessment/__generated__/NewSelfAssessmentQuery.graphql');
          return {
            newSelfAssessmentQuery: loadQuery(RelayEnvironment, NewSelfAssessmentQuery),
          };
        },
      },
      {
        path: '/self-assessments/:id/invite',
        exact: true,
        component: JSResource('SelfAssessmentInvite', () => import('./Components/SelfAssessment/SelfAssessmentInvite')),
        prepare: (params) => {
          const SelfAssessmentInviteQuery = require('./Components/SelfAssessment/__generated__/SelfAssessmentInviteQuery.graphql');
          return {
            selfAssessmentInviteQuery: loadQuery(
              RelayEnvironment,
              SelfAssessmentInviteQuery,
              {
                id: params.id,
              },
              { fetchPolicy: 'network-only' }
            ),
          };
        },
      },
      {
        path: '/self-assessments/:id/results',
        exact: true,
        component: JSResource('SelfAssessmentResults', () =>
          import('./Components/SelfAssessment/SelfAssessmentResults')
        ),
        prepare: (params) => {
          const SelfAssessmentResultsQuery = require('./Components/SelfAssessment/__generated__/SelfAssessmentResultsQuery.graphql');
          return {
            selfAssessmentResultsQuery: loadQuery(RelayEnvironment, SelfAssessmentResultsQuery, {
              id: params.id,
            }),
          };
        },
      },
      {
        path: '/self-assessments/:id',
        exact: true,
        component: JSResource('SelfAssessment', () => import('./Components/SelfAssessment/SelfAssessment')),
        prepare: (params) => {
          const SelfAssessmentQuery = require('./Components/SelfAssessment/__generated__/SelfAssessmentQuery.graphql');
          return {
            selfAssessmentQuery: loadQuery(RelayEnvironment, SelfAssessmentQuery, {
              id: params.id,
            }),
          };
        },
      },
      {
        path: '/self-assessment-questionnaires/:id',
        exact: true,
        component: JSResource('SaQuestionnaire', () => import('./Components/SelfAssessment/SaQuestionnaire')),
        prepare: (params) => {
          const SaQuestionnaireQuery = require('./Components/SelfAssessment/__generated__/SaQuestionnaireQuery.graphql');
          return {
            saQuestionnaireQuery: loadQuery(RelayEnvironment, SaQuestionnaireQuery, {
              id: params.id,
            }),
          };
        },
      },
      {
        path: '/self-assessment-templates',
        exact: true,
        component: JSResource('SelfAssessmentTemplates', () =>
          import('./Components/SelfAssessmentTemplates/SelfAssessmentTemplates')
        ),
        prepare: () => {
          const SelfAssessmentTemplatesQuery = require('./Components/SelfAssessmentTemplates/__generated__/SelfAssessmentTemplatesQuery.graphql');
          const storageKey = 'selfAssessmentTemplates-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            limit: 25,
            page: 1,
            sortBy: 'id',
            sortDirection: 'desc',
          };
          return {
            selfAssessmentTemplatesQuery: loadQuery(RelayEnvironment, SelfAssessmentTemplatesQuery, filters, {
              fetchPolicy: 'network-only',
            }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/self-assessment-templates/new',
        exact: true,
        component: JSResource('NewSelfAssessmentTemplate', () =>
          import('./Components/NewSelfAssessmentTemplate/NewSelfAssessmentTemplate')
        ),
        prepare: () => ({}),
      },
      {
        path: '/self-assessment-templates/:id',
        exact: true,
        component: JSResource('SelfAssessmentTemplate', () =>
          import('./Components/SelfAssessmentTemplate/SelfAssessmentTemplate')
        ),
        prepare: (params) => {
          const SelfAssessmentTemplateQuery = require('./Components/SelfAssessmentTemplate/__generated__/SelfAssessmentTemplateQuery.graphql');
          return {
            selfAssessmentTemplateQuery: loadQuery(RelayEnvironment, SelfAssessmentTemplateQuery, {
              id: params.id,
            }),
          };
        },
      },
      {
        path: '/settings',
        component: JSResource('Settings', () => import('./Components/Settings/Settings')),
        prepare: () => {
          const SettingsQuery = require('./Components/Settings/__generated__/SettingsQuery.graphql');
          return {
            settingsQuery: loadQuery(RelayEnvironment, SettingsQuery),
          };
        },
        routes: [
          {
            path: '/settings/organisation',
            exact: true,
            component: JSResource('Organisation', () => import('./Components/Organisation/Organisation')),
            prepare: () => {
              const OrganisationQuery = require('./Components/Organisation/__generated__/OrganisationQuery.graphql');
              return {
                organisationQuery: loadQuery(RelayEnvironment, OrganisationQuery),
              };
            },
          },
          {
            path: '/settings/authorisation',
            exact: true,
            component: JSResource('Authorisation', () => import('./Components/Authorisation/Authorisation')),
            prepare: () => {
              const AuthorisationQuery = require('./Components/Authorisation/__generated__/AuthorisationQuery.graphql');
              return {
                authorisationQuery: loadQuery(RelayEnvironment, AuthorisationQuery),
              };
            },
          },
          {
            path: '/settings/business-units',
            exact: true,
            component: JSResource('BusinessUnits', () => import('./Components/BusinessUnits/BusinessUnits')),
            prepare: () => {
              const BusinessUnitsQuery = require('./Components/BusinessUnits/__generated__/BusinessUnitsQuery.graphql');
              return {
                businessUnitsQuery: loadQuery(RelayEnvironment, BusinessUnitsQuery),
              };
            },
          },
          {
            path: '/settings/dashboard',
            exact: true,
            component: JSResource('DashboardSettings', () =>
              import('./Components/DashboardSettings/DashboardSettings')
            ),
            prepare: () => {
              const DashboardSettingsQuery = require('./Components/DashboardSettings/__generated__/DashboardSettingsQuery.graphql');
              return {
                dashboardSettingsQuery: loadQuery(RelayEnvironment, DashboardSettingsQuery),
              };
            },
          },
          {
            path: '/settings/damage-amounts',
            exact: true,
            component: JSResource('DamageAmounts', () => import('./Components/DamageAmounts/DamageAmounts')),
            prepare: () => {
              const DamageAmountsQuery = require('./Components/DamageAmounts/__generated__/DamageAmountsQuery.graphql');
              const storageKey = 'damage-amounts-filter';
              const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
                limit: 25,
                query: '',
                sortBy: 'minimum_amount',
                sortDirection: 'asc',
              };
              return {
                damageAmountsQuery: loadQuery(RelayEnvironment, DamageAmountsQuery, filters, {
                  fetchPolicy: 'network-only',
                }),
                filters,
                storageKey,
              };
            },
          },
          {
            path: '/settings/data-options',
            exact: true,
            component: JSResource('DataOptions', () => import('./Components/DataOptions/DataOptions')),
            prepare: () => {
              const DataOptionsQuery = require('./Components/DataOptions/__generated__/DataOptionsQuery.graphql');
              const storageKey = 'data-options-filter';
              const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
                limit: 25,
                query: '',
                sortBy: 'name',
                sortDirection: 'asc',
              };
              return {
                dataOptionsQuery: loadQuery(RelayEnvironment, DataOptionsQuery, filters, {
                  fetchPolicy: 'network-only',
                }),
                filters,
                storageKey,
              };
            },
          },
          {
            path: '/settings/data-subjects',
            exact: true,
            component: JSResource('DataSubjects', () => import('./Components/DataSubjects/DataSubjects')),
            prepare: () => {
              const DataSubjectsQuery = require('./Components/DataSubjects/__generated__/DataSubjectsQuery.graphql');
              const storageKey = 'data-subjects-filter';
              const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
                limit: 25,
                query: '',
                sortBy: 'name',
                sortDirection: 'asc',
              };
              return {
                dataSubjectsQuery: loadQuery(RelayEnvironment, DataSubjectsQuery, filters, {
                  fetchPolicy: 'network-only',
                }),
                filters,
                storageKey,
              };
            },
          },
          {
            path: '/settings/task-categories',
            exact: true,
            component: JSResource('TaskCategories', () => import('./Components/TaskCategories/TaskCategories')),
            prepare: () => {
              const TaskCategoriesQuery = require('./Components/TaskCategories/__generated__/TaskCategoriesQuery.graphql');
              const storageKey = 'task-categories-filter';
              const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
                limit: 25,
                query: '',
                sortBy: 'name',
                sortDirection: 'asc',
              };
              return {
                taskCategoriesQuery: loadQuery(RelayEnvironment, TaskCategoriesQuery, filters, {
                  fetchPolicy: 'network-only',
                }),
                filters,
                storageKey,
              };
            },
          },
          {
            path: '/settings/deviations',
            exact: true,
            component: JSResource('DeviationTypesOrganisations', () =>
              import('./Components/DeviationTypesOrganisations/DeviationTypesOrganisations')
            ),
            prepare: () => {
              const DeviationTypesOrganisationsQuery = require('./Components/DeviationTypesOrganisations/__generated__/DeviationTypesOrganisationsQuery.graphql');
              return {
                deviationTypesOrganisationsQuery: loadQuery(RelayEnvironment, DeviationTypesOrganisationsQuery),
              };
            },
          },
          {
            path: '/settings/deviations/:deviationType',
            exact: true,
            component: JSResource('DeviationTypesOrganisation', () =>
              import('./Components/DeviationTypesOrganisation/DeviationTypesOrganisation')
            ),
            prepare: ({ deviationType }) => {
              const DeviationTypesOrganisationQuery = require('./Components/DeviationTypesOrganisation/__generated__/DeviationTypesOrganisationQuery.graphql');
              return {
                deviationTypesOrganisationQuery: loadQuery(RelayEnvironment, DeviationTypesOrganisationQuery, {
                  deviationType,
                }),
              };
            },
          },
          {
            path: '/settings/deviations/:deviationType/form',
            exact: true,
            component: JSResource('DeviationTypesOrganisationForm', () =>
              import('./Components/DeviationTypesOrganisationForm/DeviationTypesOrganisationForm')
            ),
            prepare: ({ deviationType }) => {
              const DeviationTypesOrganisationFormQuery = require('./Components/DeviationTypesOrganisationForm/__generated__/DeviationTypesOrganisationFormQuery.graphql');
              return {
                deviationTypesOrganisationFormQuery: loadQuery(RelayEnvironment, DeviationTypesOrganisationFormQuery, {
                  deviationType,
                  formTypes: ['incident', 'analyse', 'anonymous'],
                }),
              };
            },
          },
          {
            path: '/settings/norm-requirements',
            exact: true,
            component: JSResource('NormRequirements', () => import('./Components/NormRequirements/NormRequirements')),
            prepare: () => {
              const NormRequirementsQuery = require('./Components/NormRequirements/__generated__/NormRequirementsQuery.graphql');
              const storageKey = 'norm-requirements-filter';
              const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
                limit: 25,
                normParagraphIds: [],
                normRequirementTypes: [],
                page: 1,
                query: '',
                sortBy: 'name',
                sortDirection: 'asc',
                statuses: [],
              };
              return {
                normRequirementsQuery: loadQuery(RelayEnvironment, NormRequirementsQuery, filters, {
                  fetchPolicy: 'network-only',
                }),
                filters,
                storageKey,
              };
            },
          },
          {
            path: '/settings/norm-requirements/new',
            exact: true,
            component: JSResource('NewNormRequirement', () =>
              import('./Components/NormRequirements/NewNormRequirement')
            ),
            prepare: () => {
              const NormRequirementLocalQuery = require('./Components/NormRequirements/__generated__/NewNormRequirementLocalQuery.graphql');
              const NormRequirementQuery = require('./Components/NormRequirements/__generated__/NewNormRequirementQuery.graphql');
              return {
                normRequirementLocalQuery: loadQuery(
                  RelayEnvironment,
                  NormRequirementLocalQuery,
                  { id: 'client:NormRequirement' },
                  { fetchPolicy: 'store-only' }
                ),
                normRequirementQuery: loadQuery(RelayEnvironment, NormRequirementQuery),
              };
            },
          },
          {
            path: '/settings/norm-requirements/:id',
            exact: true,
            component: JSResource('NormRequirement', () => import('./Components/NormRequirement/NormRequirement')),
            prepare: ({ id }) => {
              const NormRequirementQuery = require('./Components/NormRequirement/__generated__/NormRequirementQuery.graphql');
              return {
                normRequirementQuery: loadQuery(RelayEnvironment, NormRequirementQuery, { id }),
              };
            },
          },
          {
            path: '/settings/security',
            exact: true,
            component: JSResource('Security', () => import('./Components/Security/Security')),
            prepare: () => {
              const SecurityQuery = require('./Components/Security/__generated__/SecurityQuery.graphql');
              return {
                securityQuery: loadQuery(RelayEnvironment, SecurityQuery),
              };
            },
          },
          {
            path: '/settings/risk-attitude',
            exact: true,
            component: JSResource('RiskAttitude', () => import('./Components/RiskAttitude/RiskAttitude')),
            prepare: () => {
              const RiskAttitudeQuery = require('./Components/RiskAttitude/__generated__/RiskAttitudeQuery.graphql');
              const id = sessionStorage.getItem('organisationId');
              return {
                riskAttitudeQuery: loadQuery(RelayEnvironment, RiskAttitudeQuery, { id }),
              };
            },
          },
          {
            path: '/settings/tooltips',
            exact: true,
            component: JSResource('Tooltips', () => import('./Components/Tooltips/Tooltips')),
            prepare: () => {
              const TooltipsQuery = require('./Components/Tooltips/__generated__/TooltipsQuery.graphql');
              return {
                tooltipsQuery: loadQuery(RelayEnvironment, TooltipsQuery),
              };
            },
          },
        ],
      },
      {
        path: '/profile',
        exact: false,
        component: JSResource('Profile', () => import('./Components/Profile/Profile')),
        prepare: () => ({}),
        routes: [
          {
            path: '/profile/info',
            exact: true,
            component: JSResource('CurrentMemberProfile', () =>
              import('./Components/CurrentMember/CurrentMemberProfile')
            ),
            prepare: () => {
              const CurrentMemberProfileQuery = require('./Components/CurrentMember/__generated__/CurrentMemberProfileQuery.graphql');
              return {
                currentMemberProfileQuery: loadQuery(RelayEnvironment, CurrentMemberProfileQuery),
              };
            },
          },
          {
            path: '/profile/notifications',
            exact: true,
            component: JSResource('Notifications', () => import('./Components/CurrentMember/Notifications')),
            prepare: () => {
              const NotificationsQuery = require('./Components/CurrentMember/__generated__/NotificationsQuery.graphql');
              return {
                notificationsQuery: loadQuery(RelayEnvironment, NotificationsQuery),
              };
            },
          },
          {
            path: '/profile/account',
            exact: true,
            component: JSResource('CurrentMemberAccount', () =>
              import('./Components/CurrentMember/CurrentMemberAccount')
            ),
            prepare: () => {
              const CurrentMemberAccountQuery = require('./Components/CurrentMember/__generated__/CurrentMemberAccountQuery.graphql');
              return {
                currentMemberAccountQuery: loadQuery(RelayEnvironment, CurrentMemberAccountQuery),
              };
            },
          },
        ],
      },
      {
        path: '/tasks',
        exact: true,
        component: JSResource('Tasks', () => import('./Components/Tasks/Tasks')),
        prepare: () => {
          const date = new Date();
          date.setMonth(date.getMonth() + 1);

          const TasksQuery = require('./Components/Tasks/__generated__/TasksOverviewQuery.graphql');
          const storageKey = 'tasks-filter';
          const filters = JSON.parse(sessionStorage.getItem(storageKey)) || {
            assignedToBusinessUnitIds: [],
            assignedToIds: [],
            beginStartDate: '',
            endStartDate: date.toISOString(),
            limit: 25,
            normParagraphIds: [],
            organisationIds: [],
            page: 1,
            priorities: [],
            query: '',
            reviewerBusinessUnitIds: [],
            reviewerIds: [],
            sortBy: 'id',
            sortDirection: 'desc',
            statuses: ['new', 'assigned', 'overdue', 'review', 'waiting', 'rejected'],
            taskCategoryIds: [],
            typeOfMeasures: [],
          };
          return {
            tasksQuery: loadQuery(RelayEnvironment, TasksQuery, filters, { fetchPolicy: 'network-only' }),
            filters,
            storageKey,
          };
        },
      },
      {
        path: '/tasks/new',
        exact: true,
        component: JSResource('NewTask', () => import('./Components/NewTask/NewTask')),
        prepare: () => {
          const NewTaskLocalQuery = require('./Components/NewTask/__generated__/NewTaskLocalQuery.graphql');
          const NewTaskQuery = require('./Components/NewTask/__generated__/NewTaskQuery.graphql');
          return {
            newTaskLocalQuery: loadQuery(
              RelayEnvironment,
              NewTaskLocalQuery,
              { id: 'client:Task' },
              { fetchPolicy: 'store-only' }
            ),
            newTaskQuery: loadQuery(RelayEnvironment, NewTaskQuery),
          };
        },
      },
      {
        path: '/tasks/:id',
        exact: true,
        component: JSResource('Task', () => import('./Components/Task/Task')),
        prepare: (params) => {
          const TaskQuery = require('./Components/Task/__generated__/TaskQuery.graphql');
          return {
            taskQuery: loadQuery(RelayEnvironment, TaskQuery, { id: params.id, deviationId: '', loadDeviation: false }),
          };
        },
      },
      {
        path: '/tasks/:taskId/documents/:id',
        exact: true,
        component: JSResource('Document', () => import('./Components/Document/Document')),
        prepare: (params) => {
          const DocumentQuery = require('./Components/Document/__generated__/DocumentQuery.graphql');
          return {
            documentQuery: loadQuery(RelayEnvironment, DocumentQuery, {
              id: params.id,
              loadDeviation: false,
              loadTask: true,
              deviationId: '',
              taskId: params.taskId,
            }),
          };
        },
      },
      {
        path: '/tasks/:taskId/text-blocks/:id',
        exact: true,
        component: JSResource('TextBlock', () => import('./Components/TextBlock/TextBlock')),
        prepare: (params) => {
          const TextBlockQuery = require('./Components/TextBlock/__generated__/TextBlockQuery.graphql');
          return {
            textBlockQuery: loadQuery(RelayEnvironment, TextBlockQuery, {
              id: params.id,
              deviationId: '',
              loadDeviation: false,
              loadManualParagraph: false,
              loadTask: true,
              manualParagraphId: '',
              taskId: params.taskId,
            }),
          };
        },
      },
      {
        path: '*',
        component: JSResource('NotFound', () => import('./Components/NotFound/NotFound')),
        prepare: () => ({}),
      },
    ],
  },
];

export default routes;
