/**
 * @generated SignedSource<<e2466ebf2009d19de08e688bc38f3fe0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptTermsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Term",
        "kind": "LinkedField",
        "name": "term",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AcceptTermsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Term",
        "kind": "LinkedField",
        "name": "term",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fa399725ef5c060f116b781b6f5d9159",
    "id": null,
    "metadata": {},
    "name": "AcceptTermsQuery",
    "operationKind": "query",
    "text": "query AcceptTermsQuery {\n  term {\n    content\n    id\n  }\n}\n"
  }
};
})();

node.hash = "c51006d819d9e24ce05ae235eb449847";

module.exports = node;
