/**
 * @generated SignedSource<<01843ff69b4839f5eeba5c8d3234a222>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasChildOrganisations",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportMembersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessUnit",
            "kind": "LinkedField",
            "name": "businessUnits",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitle",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MemberFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ImportMembersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessUnit",
            "kind": "LinkedField",
            "name": "businessUnits",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitle",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aee1663866eef8af6cc1efd26d9b41e0",
    "id": null,
    "metadata": {},
    "name": "ImportMembersQuery",
    "operationKind": "query",
    "text": "query ImportMembersQuery {\n  currentOrganisation {\n    databaseId\n    hasChildOrganisations\n    id\n  }\n  organisations {\n    databaseId\n    companyName\n    businessUnits {\n      databaseId\n      name\n      id\n    }\n    jobTitles {\n      databaseId\n      name\n      id\n    }\n    id\n  }\n  ...MemberFragment_query\n}\n\nfragment MemberFragment_query on Query {\n  currentOrganisation {\n    databaseId\n    hasChildOrganisations\n    id\n  }\n  organisations {\n    databaseId\n    companyName\n    businessUnits {\n      databaseId\n      name\n      id\n    }\n    jobTitles {\n      databaseId\n      name\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "7756843038da26db400b9ff7da4a85e5";

module.exports = node;
