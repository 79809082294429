/**
 * @generated SignedSource<<b3244277d718f838b7daa97912062906>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SecurityQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ApiTokens_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LoginSettings_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SecurityQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApiTokenConnection",
        "kind": "LinkedField",
        "name": "apiTokens",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ApiToken",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasDrive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasTotp",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "passwordPolicy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minimumPasswordLength",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEnforcingStrongPassword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPasswordReusePolicy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totpSessionExpiresAfter",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SsoConfiguration",
            "kind": "LinkedField",
            "name": "ssoConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "databaseId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "certFingerPrints",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadataUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "protocol",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70de5cbe7042e724ddac94bcfd9a7646",
    "id": null,
    "metadata": {},
    "name": "SecurityQuery",
    "operationKind": "query",
    "text": "query SecurityQuery {\n  ...ApiTokens_query\n  ...LoginSettings_query\n}\n\nfragment ApiToken_apiToken on ApiToken {\n  id\n  name\n  token\n}\n\nfragment ApiTokens_query on Query {\n  apiTokens {\n    nodes {\n      id\n      ...ApiToken_apiToken\n    }\n  }\n}\n\nfragment LoginSettings_query on Query {\n  organisations {\n    hasDrive\n    hasTotp\n    id\n    passwordPolicy\n    minimumPasswordLength\n    isEnforcingStrongPassword\n    hasPasswordReusePolicy\n    totpSessionExpiresAfter\n    ssoConfiguration {\n      databaseId\n      certFingerPrints\n      metadataUrl\n      protocol\n      url\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "4ce7b08b68e2b3e28a4e910a322bc207";

module.exports = node;
