/**
 * @generated SignedSource<<06ebf551b0bb8419514b1bb927682be2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortDirection"
},
v5 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DataOptionsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DataOptionList_query"
      },
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "DataOptionListRefetch_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "DataOptionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "DataOptionPagination",
        "kind": "LinkedField",
        "name": "dataOptionPagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DataOption",
            "kind": "LinkedField",
            "name": "dataOptions",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataOptionLinksCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviationDataOptionsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c0b00278320f2d6aded5fd898f25549",
    "id": null,
    "metadata": {},
    "name": "DataOptionsQuery",
    "operationKind": "query",
    "text": "query DataOptionsQuery(\n  $query: String\n  $limit: Int\n  $sortBy: String\n  $sortDirection: String\n  $page: Int\n) {\n  ...DataOptionList_query\n  ...DataOptionListRefetch_query_1JgTUc\n}\n\nfragment DataOptionListRefetch_query_1JgTUc on Query {\n  dataOptionPagination(query: $query, limit: $limit, sortBy: $sortBy, sortDirection: $sortDirection, page: $page) {\n    dataOptions {\n      id\n      ...DataOption_dataOption\n    }\n    totalCount\n  }\n}\n\nfragment DataOptionList_query on Query {\n  organisations {\n    databaseId\n    id\n  }\n}\n\nfragment DataOption_dataOption on DataOption {\n  dataOptionLinksCount\n  deviationDataOptionsCount\n  id\n  name\n  ...ReplaceDataOptionsModal_dataOption\n}\n\nfragment ReplaceDataOptionsModal_dataOption on DataOption {\n  id\n  name\n}\n"
  }
};
})();

node.hash = "8dc0d6f9f72f28347de643d6cdafb70f";

module.exports = node;
