/**
 * @generated SignedSource<<8f982950110f4ad8ba2f350cbe9f4958>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "Literal",
    "name": "roles",
    "value": [
      "admin",
      "editor",
      "author"
    ]
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "databaseId",
    "storageKey": null
  },
  (v7/*: any*/)
],
v9 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewInformationSourceLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InformationSource",
        "kind": "LinkedField",
        "name": "informationSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitleConnection",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": "users(roles:[\"admin\",\"editor\",\"author\"])"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InformationSourceFormFragment_informationSource"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewInformationSourceLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InformationSource",
        "kind": "LinkedField",
        "name": "informationSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitleConnection",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": "users(roles:[\"admin\",\"editor\",\"author\"])"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availability",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ciaExplanation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confidentiality",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "integrity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "manualParagraphIds",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "normRequirementIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rto",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rpo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v3/*: any*/),
            "type": "CiaFields",
            "abstractKey": "__isCiaFields"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": "users(roles:[\"admin\",\"editor\",\"author\"])"
              },
              (v9/*: any*/)
            ],
            "type": "UsersInterface",
            "abstractKey": "__isUsersInterface"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitleConnection",
                "kind": "LinkedField",
                "name": "jobTitles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobTitle",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "type": "JobTitlesInterface",
            "abstractKey": "__isJobTitlesInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57eba30c77b4bbefb54aee5a0983fa5f",
    "id": null,
    "metadata": {},
    "name": "NewInformationSourceLocalQuery",
    "operationKind": "query",
    "text": "query NewInformationSourceLocalQuery(\n  $id: ID!\n) {\n  informationSource(id: $id) {\n    jobTitles {\n      nodes {\n        __typename\n        id\n      }\n    }\n    users(roles: [\"admin\", \"editor\", \"author\"]) {\n      __typename\n      id\n    }\n    ...InformationSourceFormFragment_informationSource\n    id\n  }\n}\n\nfragment CiaForm_ciaFields on CiaFields {\n  __isCiaFields: __typename\n  __typename\n}\n\nfragment InformationSourceFormFragment_informationSource on InformationSource {\n  availability\n  ciaExplanation\n  confidentiality\n  integrity\n  jobTitleId\n  manualParagraphIds\n  name\n  normRequirementIds\n  organisationId\n  resourceIds\n  rto\n  rpo\n  userId\n  ...CiaForm_ciaFields\n  ...OwnerFilter_usersInterface_4O3V5\n  ...InformationSourceForm_jobTitlesInterface\n}\n\nfragment InformationSourceForm_jobTitlesInterface on JobTitlesInterface {\n  __isJobTitlesInterface: __typename\n  jobTitles {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n\nfragment OwnerFilter_usersInterface_4O3V5 on UsersInterface {\n  __isUsersInterface: __typename\n  users(roles: [\"admin\", \"editor\", \"author\"]) {\n    databaseId\n    name\n    id\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n"
  }
};
})();

node.hash = "a68329d0650ee0f884b859ab12d5edb3";

module.exports = node;
