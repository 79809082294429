/**
 * @generated SignedSource<<9d4d42df3b38e3584d0b764b25e9af0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "auditId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "focusArea",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "auditId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v10 = [
  (v6/*: any*/),
  (v3/*: any*/)
],
v11 = [
  (v9/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "number",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditFindingsClusterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AuditFindingsCluster",
        "kind": "LinkedField",
        "name": "auditFindingsCluster",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuditAnswersList_auditFindingsCluster"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuditFindingsClusterDeviation_auditFindings"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuditFindingsClusterForm_auditFindingsCluster"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuditAnswersList_audit"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuditFindingsClusterDeviation_organisation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AuditFindingsClusterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AuditFindingsCluster",
        "kind": "LinkedField",
        "name": "auditFindingsCluster",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditAnswer",
            "kind": "LinkedField",
            "name": "auditAnswers",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "answer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auditFindingsClusterId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditQuestion",
                "kind": "LinkedField",
                "name": "auditQuestion",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "question",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Deviation",
            "kind": "LinkedField",
            "name": "deviation",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviationType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "assignedTo",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "createdBy",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualParagraph",
                "kind": "LinkedField",
                "name": "manualParagraphs",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "NormParagraph",
                "kind": "LinkedField",
                "name": "normParagraphs",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasManual",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Audit",
        "kind": "LinkedField",
        "name": "audit",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v6/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditFindingsCluster",
            "kind": "LinkedField",
            "name": "auditFindingsClusters",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasChildOrganisations",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ba0205ddc7d41fcce0e918e37bc3dd1",
    "id": null,
    "metadata": {},
    "name": "AuditFindingsClusterQuery",
    "operationKind": "query",
    "text": "query AuditFindingsClusterQuery(\n  $id: ID!\n  $auditId: ID!\n) {\n  auditFindingsCluster(id: $id) {\n    id\n    description\n    focusArea\n    name\n    rating\n    ...AuditAnswersList_auditFindingsCluster\n    ...AuditFindingsClusterDeviation_auditFindings\n    ...AuditFindingsClusterForm_auditFindingsCluster\n  }\n  audit(id: $auditId) {\n    databaseId\n    name\n    ...AuditAnswersList_audit\n    id\n  }\n  currentOrganisation {\n    ...AuditFindingsClusterDeviation_organisation\n    id\n  }\n}\n\nfragment AuditAnswersList_audit on Audit {\n  id\n  auditFindingsClusters {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment AuditAnswersList_auditFindingsCluster on AuditFindingsCluster {\n  auditAnswers {\n    databaseId\n    answer\n    auditFindingsClusterId\n    auditQuestion {\n      question\n      id\n    }\n    id\n  }\n}\n\nfragment AuditFindingsClusterDeviation_auditFindings on AuditFindingsCluster {\n  id\n  databaseId\n  description\n  focusArea\n  name\n  deviation {\n    ...Deviation_deviation\n    id\n  }\n}\n\nfragment AuditFindingsClusterDeviation_organisation on Organisation {\n  databaseId\n  hasChildOrganisations\n  ...Deviations_organisation\n}\n\nfragment AuditFindingsClusterForm_auditFindingsCluster on AuditFindingsCluster {\n  id\n  description\n  focusArea\n  name\n  rating\n}\n\nfragment Deviation_deviation on Deviation {\n  databaseId\n  deviationType\n  createdAt\n  focusArea\n  name\n  status\n  assignedTo {\n    name\n    id\n  }\n  createdBy {\n    name\n    id\n  }\n  manualParagraphs {\n    databaseId\n    name\n    number\n    id\n  }\n  normParagraphs {\n    databaseId\n    name\n    number\n    id\n  }\n  organisation {\n    companyName\n    hasManual\n    id\n  }\n}\n\nfragment Deviations_organisation on Organisation {\n  hasChildOrganisations\n}\n"
  }
};
})();

node.hash = "ba91c36402e42039f4a78cf9b65b6f46";

module.exports = node;
