/**
 * @generated SignedSource<<d24c97de43a4450ef4b82c94ecfe0b92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "Literal",
    "name": "roles",
    "value": [
      "admin",
      "editor",
      "author"
    ]
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v7/*: any*/)
],
v11 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewSupplierLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Supplier",
        "kind": "LinkedField",
        "name": "supplier",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitleConnection",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": "users(roles:[\"admin\",\"editor\",\"author\"])"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SupplierFormFragment_supplier"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewSupplierLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Supplier",
        "kind": "LinkedField",
        "name": "supplier",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobTitleConnection",
            "kind": "LinkedField",
            "name": "jobTitles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": "users(roles:[\"admin\",\"editor\",\"author\"])"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availability",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ciaExplanation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confidentiality",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactInformation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "integrity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitleId",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "normParagraphIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "normRequirementIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organisationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rpo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rto",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Attachment",
            "kind": "LinkedField",
            "name": "attachments",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "filename",
                "storageKey": null
              },
              (v9/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AttachmentUrl",
            "kind": "LinkedField",
            "name": "attachmentUrls",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v5/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v3/*: any*/),
            "type": "CiaFields",
            "abstractKey": "__isCiaFields"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": (v10/*: any*/),
                "storageKey": "users(roles:[\"admin\",\"editor\",\"author\"])"
              },
              (v11/*: any*/)
            ],
            "type": "UsersInterface",
            "abstractKey": "__isUsersInterface"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitleConnection",
                "kind": "LinkedField",
                "name": "jobTitles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobTitle",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "type": "JobTitlesInterface",
            "abstractKey": "__isJobTitlesInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f56a0fde327af6038c4e98ee6fc77ddf",
    "id": null,
    "metadata": {},
    "name": "NewSupplierLocalQuery",
    "operationKind": "query",
    "text": "query NewSupplierLocalQuery(\n  $id: ID!\n) {\n  supplier(id: $id) {\n    jobTitles {\n      nodes {\n        __typename\n        id\n      }\n    }\n    users(roles: [\"admin\", \"editor\", \"author\"]) {\n      __typename\n      id\n    }\n    ...SupplierFormFragment_supplier\n    id\n  }\n}\n\nfragment AttachmentLink_attachment on Attachment {\n  filename\n  url\n}\n\nfragment CiaForm_ciaFields on CiaFields {\n  __isCiaFields: __typename\n  __typename\n}\n\nfragment OwnerFilter_usersInterface_4O3V5 on UsersInterface {\n  __isUsersInterface: __typename\n  users(roles: [\"admin\", \"editor\", \"author\"]) {\n    databaseId\n    name\n    id\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n\nfragment SupplierFormFragment_supplier on Supplier {\n  availability\n  ciaExplanation\n  confidentiality\n  contactInformation\n  integrity\n  jobTitleId\n  name\n  normParagraphIds\n  normRequirementIds\n  organisationId\n  resourceIds\n  rpo\n  rto\n  userId\n  attachments {\n    databaseId\n    ...AttachmentLink_attachment\n    id\n  }\n  attachmentUrls {\n    databaseId\n    id\n    url\n  }\n  ...CiaForm_ciaFields\n  ...OwnerFilter_usersInterface_4O3V5\n  ...SupplierForm_jobTitlesInterface\n}\n\nfragment SupplierForm_jobTitlesInterface on JobTitlesInterface {\n  __isJobTitlesInterface: __typename\n  jobTitles {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n"
  }
};
})();

node.hash = "d5f09eaecc019f617233aed04ae4c9c8";

module.exports = node;
