import { createContext, useCallback, useContext, useState } from 'react';

const NavigationContext = createContext({ showKey: 0, setShowKey: () => {} });

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = (props) => {
  const [showKey, setShowKey] = useState(0);

  const increment = useCallback(() => {
    setShowKey((sk) => sk + 1);
  }, []);

  return <NavigationContext.Provider value={{ showKey, increment }}>{props.children}</NavigationContext.Provider>;
};
