/**
 * @generated SignedSource<<9821517e921bbf36b8f80930a127a325>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortDirection"
},
v5 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskCategoriesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TaskCategoryList_query"
      },
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "TaskCategoryListRefetch_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "TaskCategoriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "TaskCategoryPagination",
        "kind": "LinkedField",
        "name": "taskCategoryPagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskCategory",
            "kind": "LinkedField",
            "name": "taskCategories",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tasksCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d370c825f5bf72b624a7325089b01f6a",
    "id": null,
    "metadata": {},
    "name": "TaskCategoriesQuery",
    "operationKind": "query",
    "text": "query TaskCategoriesQuery(\n  $query: String\n  $limit: Int\n  $sortBy: String\n  $sortDirection: String\n  $page: Int\n) {\n  ...TaskCategoryList_query\n  ...TaskCategoryListRefetch_query_1JgTUc\n}\n\nfragment TaskCategoryListRefetch_query_1JgTUc on Query {\n  taskCategoryPagination(query: $query, limit: $limit, sortBy: $sortBy, sortDirection: $sortDirection, page: $page) {\n    taskCategories {\n      id\n      ...TaskCategory_taskCategory\n    }\n    totalCount\n  }\n}\n\nfragment TaskCategoryList_query on Query {\n  organisations {\n    databaseId\n    id\n  }\n}\n\nfragment TaskCategory_taskCategory on TaskCategory {\n  tasksCount\n  id\n  name\n}\n"
  }
};
})();

node.hash = "14d101a8eb8b819545bef2ea4ab5ecdd";

module.exports = node;
