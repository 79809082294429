/**
 * @generated SignedSource<<b9121dddb5255ea4bbdfe3fe6e25ccbd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DamageAmountsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DamageAmountList_query"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "DamageAmountListRefetch_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DamageAmountsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DamageAmountPagination",
        "kind": "LinkedField",
        "name": "damageAmountPagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DamageAmount",
            "kind": "LinkedField",
            "name": "damageAmounts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minimumAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maximumAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d67c3836a79767ba44f972e169e27f3",
    "id": null,
    "metadata": {},
    "name": "DamageAmountsQuery",
    "operationKind": "query",
    "text": "query DamageAmountsQuery(\n  $limit: Int\n  $page: Int\n  $query: String\n  $sortBy: String\n  $sortDirection: String\n) {\n  ...DamageAmountList_query\n  ...DamageAmountListRefetch_query_1JgTUc\n}\n\nfragment DamageAmountListRefetch_query_1JgTUc on Query {\n  damageAmountPagination(query: $query, limit: $limit, sortBy: $sortBy, sortDirection: $sortDirection, page: $page) {\n    damageAmounts {\n      id\n      ...DamageAmount_damageAmount\n    }\n    totalCount\n  }\n}\n\nfragment DamageAmountList_query on Query {\n  organisations {\n    databaseId\n    id\n  }\n}\n\nfragment DamageAmount_damageAmount on DamageAmount {\n  id\n  minimumAmount\n  maximumAmount\n  name\n}\n"
  }
};
})();

node.hash = "13002d58bcd30e06efd6ea0286f37fc2";

module.exports = node;
