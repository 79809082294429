/**
 * @generated SignedSource<<26e3c81eaa1ce0d8da9cb8ce1a4976d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auditId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  (v5/*: any*/),
  (v3/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "interviewee",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuditQuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuditQuestionnaire",
        "kind": "LinkedField",
        "name": "auditQuestionnaire",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuditAnswers_auditQuestionnaire"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "interviewee",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuditQuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuditQuestionnaire",
        "kind": "LinkedField",
        "name": "auditQuestionnaire",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditAnswer",
            "kind": "LinkedField",
            "name": "auditAnswers",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auditFindingsClusterId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "documentationRealisation",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "implementationRealisation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditQuestion",
                "kind": "LinkedField",
                "name": "auditQuestion",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "question",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ManualParagraph",
                    "kind": "LinkedField",
                    "name": "manualParagraph",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Document",
                        "kind": "LinkedField",
                        "name": "documents",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "redirectUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Resource",
                        "kind": "LinkedField",
                        "name": "resources",
                        "plural": true,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "documentsCount",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "resourcesCount",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NormParagraph",
                    "kind": "LinkedField",
                    "name": "normParagraphs",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v5/*: any*/),
                      (v9/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditAnswer",
                "kind": "LinkedField",
                "name": "otherAuditAnswers",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditQuestionnaire",
                    "kind": "LinkedField",
                    "name": "auditQuestionnaire",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Audit",
            "kind": "LinkedField",
            "name": "audit",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditFindingsCluster",
                "kind": "LinkedField",
                "name": "auditFindingsClusters",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ebfa0e186f851c3be2484c817fb261a",
    "id": null,
    "metadata": {},
    "name": "AuditQuestionnaireQuery",
    "operationKind": "query",
    "text": "query AuditQuestionnaireQuery(\n  $id: ID!\n) {\n  auditQuestionnaire(id: $id) {\n    ...AuditAnswers_auditQuestionnaire\n    auditId\n    id\n    summary\n    interviewee {\n      name\n      id\n    }\n  }\n}\n\nfragment AttachmentLink_attachment on Attachment {\n  filename\n  url\n}\n\nfragment AuditAnswerAuditQuestionnaire_auditAnswer on AuditAnswer {\n  answer\n  auditFindingsClusterId\n  documentationRealisation\n  id\n  implementationRealisation\n  attachments {\n    ...AttachmentLink_attachment\n    databaseId\n    id\n  }\n  auditQuestion {\n    description\n    question\n    manualParagraph {\n      ...AuditAnswerDocuments_manualParagraph\n      ...AuditAnswerResources_manualParagraph\n      documentsCount\n      name\n      number\n      resourcesCount\n      id\n    }\n    normParagraphs {\n      databaseId\n      name\n      number\n      id\n    }\n    id\n  }\n  otherAuditAnswers {\n    answer\n    id\n    auditQuestionnaire {\n      interviewee {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment AuditAnswerDocuments_manualParagraph on ManualParagraph {\n  documents {\n    id\n    name\n    redirectUrl\n    type\n  }\n}\n\nfragment AuditAnswerResources_manualParagraph on ManualParagraph {\n  resources {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment AuditAnswer_audit on Audit {\n  auditFindingsClusters {\n    databaseId\n    name\n    id\n  }\n}\n\nfragment AuditAnswers_auditQuestionnaire on AuditQuestionnaire {\n  auditAnswers {\n    ...AuditAnswerAuditQuestionnaire_auditAnswer\n    answer\n    id\n    auditQuestion {\n      id\n      question\n      manualParagraph {\n        name\n        number\n        id\n      }\n    }\n  }\n  audit {\n    ...AuditAnswer_audit\n    id\n  }\n  interviewee {\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "b6b41dd4d15074d9a2a08dc8b5f2fbd1";

module.exports = node;
