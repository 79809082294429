/**
 * @generated SignedSource<<a6979ef0bd986d6bba98bf0344ee4257>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "includeParentOrganisation",
  "value": true
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v0/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "currentOrganisation",
      "value": true
    },
    (v1/*: any*/)
  ],
  "concreteType": "JobTitleConnection",
  "kind": "LinkedField",
  "name": "jobTitles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JobTitle",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "jobTitles(currentOrganisation:true,includeParentOrganisation:true)"
},
v6 = {
  "alias": null,
  "args": [
    (v1/*: any*/),
    {
      "kind": "Literal",
      "name": "roles",
      "value": [
        "admin",
        "editor",
        "author"
      ]
    },
    {
      "kind": "Literal",
      "name": "sortBy",
      "value": "name"
    },
    {
      "kind": "Literal",
      "name": "sortDirection",
      "value": "asc"
    }
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "users",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": "users(includeParentOrganisation:true,roles:[\"admin\",\"editor\",\"author\"],sortBy:\"name\",sortDirection:\"asc\")"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasCia",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasQt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasRisk",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewInformationSourceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "InformationSourceFormFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewInformationSourceQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasRtoRpo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RiskAttitude",
            "kind": "LinkedField",
            "name": "riskAttitude",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "criticalValue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxRange",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ManualParagraph",
        "kind": "LinkedField",
        "name": "manualParagraphs",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "number",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "normRequirementType",
            "value": "information_source"
          }
        ],
        "concreteType": "NormRequirement",
        "kind": "LinkedField",
        "name": "normRequirements",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confidentiality",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "integrity",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": "normRequirements(normRequirementType:\"information_source\")"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          (v0/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasLabels",
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ResourceConnection",
        "kind": "LinkedField",
        "name": "resources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Resource",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "206133a9638aebe6e644ab79fe0a64f7",
    "id": null,
    "metadata": {},
    "name": "NewInformationSourceQuery",
    "operationKind": "query",
    "text": "query NewInformationSourceQuery {\n  currentOrganisation {\n    databaseId\n    id\n  }\n  jobTitles(currentOrganisation: true, includeParentOrganisation: true) {\n    nodes {\n      __typename\n      databaseId\n      id\n      name\n    }\n  }\n  users(includeParentOrganisation: true, roles: [\"admin\", \"editor\", \"author\"], sortBy: \"name\", sortDirection: \"asc\") {\n    __typename\n    databaseId\n    id\n    name\n  }\n  ...InformationSourceFormFragment_query\n}\n\nfragment CiaForm_query on Query {\n  currentOrganisation {\n    hasCia\n    hasQt\n    hasRisk\n    hasRtoRpo\n    riskAttitude {\n      criticalValue\n      maxRange\n      id\n    }\n    id\n  }\n}\n\nfragment InformationSourceFormFragment_query on Query {\n  manualParagraphs {\n    databaseId\n    name\n    number\n    id\n  }\n  normRequirements(normRequirementType: \"information_source\") {\n    databaseId\n    name\n    confidentiality\n    integrity\n    id\n  }\n  organisations {\n    companyName\n    databaseId\n    hasCia\n    hasLabels\n    hasQt\n    hasRisk\n    id\n  }\n  resources {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  ...CiaForm_query\n}\n"
  }
};
})();

node.hash = "cb8bd404186c7861fb114c9bd7d4d24b";

module.exports = node;
