/**
 * @generated SignedSource<<6dc8522e02b6db8f0fe1e247c4e9490a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organisationId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "manualChapterTypes",
    "value": [
      "",
      "primary_process"
    ]
  }
],
v9 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v7/*: any*/)
],
v10 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IPPAQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ManualChapter",
        "kind": "LinkedField",
        "name": "manualChapter",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "IppaManualParagraph_manualParagraph"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "IppaManualParagraphForm_manualParagraph"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ManualChapter",
        "kind": "LinkedField",
        "name": "manualChapters",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": "manualChapters(manualChapterTypes:[\"\",\"primary_process\"])"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "IppaManualParagraph_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "IppaManualParagraphForm_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IPPAQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ManualChapter",
        "kind": "LinkedField",
        "name": "manualChapter",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "informationSourceIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTitleId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "resourceIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitleConnection",
                "kind": "LinkedField",
                "name": "processJobTitles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobTitle",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualChapter",
                "kind": "LinkedField",
                "name": "manualChapter",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ManualChapter",
        "kind": "LinkedField",
        "name": "manualChapters",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": "manualChapters(manualChapterTypes:[\"\",\"primary_process\"])"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InformationSourceConnection",
        "kind": "LinkedField",
        "name": "informationSources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InformationSource",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "sortBy",
            "value": "name"
          },
          {
            "kind": "Literal",
            "name": "sortDirection",
            "value": "asc"
          }
        ],
        "concreteType": "ResourceConnection",
        "kind": "LinkedField",
        "name": "resources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Resource",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": "resources(sortBy:\"name\",sortDirection:\"asc\")"
      }
    ]
  },
  "params": {
    "cacheID": "22698daa726e5dbf2c47ac296520febd",
    "id": null,
    "metadata": {},
    "name": "IPPAQuery",
    "operationKind": "query",
    "text": "query IPPAQuery(\n  $id: ID!\n) {\n  manualChapter(id: $id) {\n    __typename\n    databaseId\n    name\n    number\n    organisationId\n    manualParagraphs {\n      id\n      ...IppaManualParagraph_manualParagraph\n      ...IppaManualParagraphForm_manualParagraph\n    }\n    id\n  }\n  manualChapters(manualChapterTypes: [\"\", \"primary_process\"]) {\n    databaseId\n    name\n    number\n    id\n  }\n  ...IppaManualParagraph_query\n  ...IppaManualParagraphForm_query\n}\n\nfragment IppaManualParagraphForm_manualParagraph on ManualParagraph {\n  id\n  databaseId\n  name\n  number\n  informationSourceIds\n  jobTitleId\n  resourceIds\n  processJobTitles {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  ...NewInformationSourceFragment_manualParagraph\n  ...NewJobTitleFragment_manualParagraph\n  ...NewResourceFragment_manualParagraph\n}\n\nfragment IppaManualParagraphForm_query on Query {\n  informationSources {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  resources(sortBy: \"name\", sortDirection: \"asc\") {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n}\n\nfragment IppaManualParagraph_manualParagraph on ManualParagraph {\n  databaseId\n  name\n  number\n  informationSourceIds\n  jobTitleId\n  resourceIds\n  processJobTitles {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  manualChapter {\n    databaseId\n    id\n  }\n}\n\nfragment IppaManualParagraph_query on Query {\n  informationSources {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  resources(sortBy: \"name\", sortDirection: \"asc\") {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n}\n\nfragment NewInformationSourceFragment_manualParagraph on ManualParagraph {\n  id\n  databaseId\n}\n\nfragment NewJobTitleFragment_manualParagraph on ManualParagraph {\n  id\n  databaseId\n}\n\nfragment NewResourceFragment_manualParagraph on ManualParagraph {\n  id\n  databaseId\n}\n"
  }
};
})();

node.hash = "5f6552a6cae917ae91ffa85e88152b5b";

module.exports = node;
