/**
 * @generated SignedSource<<8337153d3c11b33785bcc38c18601eaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortDirection"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasRisk",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "organisationIds",
    "variableName": "organisationIds"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v8/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ThreatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ThreatList_organisation"
          }
        ],
        "storageKey": null
      },
      {
        "args": (v7/*: any*/),
        "kind": "FragmentSpread",
        "name": "ThreatList_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ThreatFilters_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ThreatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasChildOrganisations",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentOrganisationId",
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "ThreatPagination",
        "kind": "LinkedField",
        "name": "threatPagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Threat",
            "kind": "LinkedField",
            "name": "threats",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JobTitle",
                "kind": "LinkedField",
                "name": "jobTitles",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualParagraph",
                "kind": "LinkedField",
                "name": "manualParagraphs",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Resource",
                "kind": "LinkedField",
                "name": "resources",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Supplier",
                "kind": "LinkedField",
                "name": "suppliers",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "includeParent",
            "value": true
          }
        ],
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v9/*: any*/),
          (v12/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": "organisations(includeParent:true)"
      }
    ]
  },
  "params": {
    "cacheID": "3dcdb70922f401eddb558e6962249597",
    "id": null,
    "metadata": {},
    "name": "ThreatsQuery",
    "operationKind": "query",
    "text": "query ThreatsQuery(\n  $organisationIds: [Int!]\n  $query: String\n  $limit: Int\n  $sortBy: String\n  $sortDirection: String\n  $page: Int\n) {\n  currentOrganisation {\n    hasRisk\n    ...ThreatList_organisation\n    id\n  }\n  ...ThreatList_query_1u8aO6\n  ...ThreatFilters_query\n}\n\nfragment ThreatFilters_query on Query {\n  organisations(includeParent: true) {\n    databaseId\n    companyName\n    id\n  }\n}\n\nfragment ThreatList_organisation on Organisation {\n  ...Threat_organisation\n  hasChildOrganisations\n  parentOrganisationId\n}\n\nfragment ThreatList_query_1u8aO6 on Query {\n  threatPagination(limit: $limit, organisationIds: $organisationIds, page: $page, query: $query, sortBy: $sortBy, sortDirection: $sortDirection) {\n    threats {\n      id\n      ...Threat_threat\n    }\n    totalCount\n  }\n}\n\nfragment Threat_organisation on Organisation {\n  hasChildOrganisations\n  parentOrganisationId\n}\n\nfragment Threat_threat on Threat {\n  databaseId\n  description\n  name\n  jobTitles {\n    databaseId\n    name\n    id\n  }\n  manualParagraphs {\n    databaseId\n    name\n    number\n    id\n  }\n  organisation {\n    companyName\n    id\n  }\n  resources {\n    databaseId\n    name\n    id\n  }\n  suppliers {\n    databaseId\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "423d7179ac1fb810aa0f678355ceeb89";

module.exports = node;
