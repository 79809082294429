/**
 * @generated SignedSource<<a5fee7ed2de1690d0ad0e18694ba469f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviationType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviationTypesOrganisationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeviationType_organisation"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeviationTypesOrganisation_organisation"
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationTypesOrganisation",
            "kind": "LinkedField",
            "name": "deviationTypesOrganisations",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeviationTypesOrganisation_deviationTypesQuestion"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeviationTypesOrganisationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domain",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviationTypesOrganisation",
            "kind": "LinkedField",
            "name": "deviationTypesOrganisations",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviationCategory",
                "kind": "LinkedField",
                "name": "deviationCategories",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviationCategoryId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Coordinator",
                "kind": "LinkedField",
                "name": "coordinators",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Member",
                    "kind": "LinkedField",
                    "name": "member",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdb1b8b2be185075e0048941eae85c03",
    "id": null,
    "metadata": {},
    "name": "DeviationTypesOrganisationsQuery",
    "operationKind": "query",
    "text": "query DeviationTypesOrganisationsQuery {\n  organisations {\n    ...DeviationType_organisation\n    ...DeviationTypesOrganisation_organisation\n    id\n    deviationTypesOrganisations {\n      id\n      deviationType\n      ...DeviationTypesOrganisation_deviationTypesQuestion\n    }\n  }\n}\n\nfragment DeviationType_organisation on Organisation {\n  databaseId\n  id\n}\n\nfragment DeviationTypesOrganisation_deviationTypesQuestion on DeviationTypesOrganisation {\n  deviationType\n  deviationCategories {\n    id\n    name\n    deviationCategoryId\n  }\n  coordinators {\n    id\n    member {\n      user {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment DeviationTypesOrganisation_organisation on Organisation {\n  domain\n  uuid\n}\n"
  }
};
})();

node.hash = "2f0aa23e8bf672f2244fb9b898e34675";

module.exports = node;
