/**
 * @generated SignedSource<<f89d80e3518307528b224a0fd72a25d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  },
  {
    "kind": "Literal",
    "name": "tokenType",
    "value": "password_reset"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasExpired",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordResetEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserToken",
        "kind": "LinkedField",
        "name": "userToken",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PasswordResetEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserToken",
        "kind": "LinkedField",
        "name": "userToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e5ad6063f8ca4bd407d19ee9df269c1",
    "id": null,
    "metadata": {},
    "name": "PasswordResetEditQuery",
    "operationKind": "query",
    "text": "query PasswordResetEditQuery(\n  $token: String!\n) {\n  userToken(token: $token, tokenType: \"password_reset\") {\n    hasExpired\n    id\n  }\n}\n"
  }
};
})();

node.hash = "678ab20bb371a5539b83d155fe421787";

module.exports = node;
