/**
 * @generated SignedSource<<7a5818d9858be0bed7155fd4f174d56a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limitDocument"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limitTextBlock"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageDocument"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageTextBlock"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v2 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v3 = {
  "kind": "Variable",
  "name": "sortDirection",
  "variableName": "sortDirection"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ManualParagraph",
  "kind": "LinkedField",
  "name": "manualParagraph",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManualSearchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "limitDocument",
            "variableName": "limitDocument"
          },
          {
            "kind": "Variable",
            "name": "limitTextBlock",
            "variableName": "limitTextBlock"
          },
          {
            "kind": "Variable",
            "name": "pageDocument",
            "variableName": "pageDocument"
          },
          {
            "kind": "Variable",
            "name": "pageTextBlock",
            "variableName": "pageTextBlock"
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "Results_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManualSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "limit",
            "variableName": "limitTextBlock"
          },
          {
            "kind": "Variable",
            "name": "page",
            "variableName": "pageTextBlock"
          },
          (v1/*: any*/)
        ],
        "concreteType": "TextBlockPagination",
        "kind": "LinkedField",
        "name": "textBlockPagination",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TextBlock",
            "kind": "LinkedField",
            "name": "textBlocks",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v6/*: any*/),
              (v8/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "limit",
            "variableName": "limitDocument"
          },
          {
            "kind": "Variable",
            "name": "page",
            "variableName": "pageDocument"
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "concreteType": "DocumentPagination",
        "kind": "LinkedField",
        "name": "documentPagination",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "classification",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revisionDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01b831b07cd842d542b07dfd96623f52",
    "id": null,
    "metadata": {},
    "name": "ManualSearchQuery",
    "operationKind": "query",
    "text": "query ManualSearchQuery(\n  $limitDocument: Int\n  $limitTextBlock: Int\n  $pageDocument: Int\n  $pageTextBlock: Int\n  $query: String\n  $sortBy: String\n  $sortDirection: String\n) {\n  ...Results_query_hxw6L\n}\n\nfragment DocumentResults_documentPagination on DocumentPagination {\n  totalCount\n  documents {\n    classification\n    databaseId\n    name\n    revisionDate\n    status\n    manualParagraph {\n      databaseId\n      number\n      id\n    }\n    user {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment ResultsDocumentsRefetchQuery_query_4sNqKk on Query {\n  documentPagination(limit: $limitDocument, page: $pageDocument, query: $query, sortBy: $sortBy, sortDirection: $sortDirection) {\n    totalCount\n    ...DocumentResults_documentPagination\n  }\n}\n\nfragment ResultsRefetchQuery_query_cS8Lk on Query {\n  textBlockPagination(limit: $limitTextBlock, page: $pageTextBlock, query: $query) {\n    totalCount\n    ...TextBlockResultsQuery_textBlockPagination\n  }\n}\n\nfragment Results_query_hxw6L on Query {\n  ...ResultsRefetchQuery_query_cS8Lk\n  ...ResultsDocumentsRefetchQuery_query_4sNqKk\n}\n\nfragment TextBlockResultsQuery_textBlockPagination on TextBlockPagination {\n  totalCount\n  textBlocks {\n    databaseId\n    description\n    name\n    manualParagraph {\n      databaseId\n      number\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "51fdff51eb7660eb58384824662ae3a8";

module.exports = node;
