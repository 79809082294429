import styled from 'styled-components';

const Card = styled.div`
  background-color: var(--card-bgcolor);
  border-radius: 12px;
  box-shadow: 0px 6px 14px -6px var(--shadow-color), 0px 10px 32px -4px var(--second-shadow-color);
  margin-bottom: 1.5rem;
  padding: 1.5rem 1.25rem;
  width: 100%;
`;

const Header = styled.div`
  ${({ border }) => border && 'border-bottom: 1px solid var(--tertiary-color); padding-bottom: 0.75rem;'}

  font-weight: 800;
  margin-bottom: 1.5rem;
`;

const Title = styled.h2`
  ${({ large }) => !large && 'font-size: 1.5rem;'}
  ${({ display }) => `display: ${display}`}
`;

Title.defaultProps = {
  display: 'block',
};

const Footer = styled.div`
  border-top: 1px solid var(--tertiary-color);
  padding-top: 0.75rem;'
`;

Card.Header = Header;
Card.Header.Title = Title;
Card.Footer = Footer;

export default Card;
