/**
 * @generated SignedSource<<345b26c82b4254e18642cefd2d1e950b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasChildOrganisations",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentOrganisationId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "SaAnswerOption",
  "kind": "LinkedField",
  "name": "saAnswerOptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionFields",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfAssessmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessment",
        "kind": "LinkedField",
        "name": "selfAssessment",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelfAssessmentChart_selfAssessment"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SaQuestion",
            "kind": "LinkedField",
            "name": "saQuestions",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfAssessmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessment",
        "kind": "LinkedField",
        "name": "selfAssessment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "progress",
                "value": 100
              }
            ],
            "concreteType": "SaQuestionnaire",
            "kind": "LinkedField",
            "name": "saQuestionnaires",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SaAnswer",
                "kind": "LinkedField",
                "name": "saAnswers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "score",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaQuestion",
                    "kind": "LinkedField",
                    "name": "saQuestion",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": "saQuestionnaires(progress:100)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SaQuestion",
            "kind": "LinkedField",
            "name": "saQuestions",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v12/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisation",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c583f838c75897eccbb2236241a87ba",
    "id": null,
    "metadata": {},
    "name": "SelfAssessmentQuery",
    "operationKind": "query",
    "text": "query SelfAssessmentQuery(\n  $id: ID!\n) {\n  selfAssessment(id: $id) {\n    ...SelfAssessmentChart_selfAssessment\n    databaseId\n    description\n    endDate\n    id\n    name\n    userId\n    organisation {\n      companyName\n      hasChildOrganisations\n      parentOrganisationId\n      id\n    }\n    saQuestions {\n      id\n      saAnswerOptions {\n        answer\n        id\n      }\n      questionFields\n    }\n    user {\n      databaseId\n      name\n      id\n    }\n  }\n}\n\nfragment SelfAssessmentChart_selfAssessment on SelfAssessment {\n  saQuestionnaires(progress: 100) {\n    saAnswers {\n      score\n      saQuestion {\n        id\n        questionFields\n      }\n      id\n    }\n    user {\n      name\n      id\n    }\n    id\n  }\n  saQuestions {\n    id\n    questionFields\n  }\n}\n"
  }
};
})();

node.hash = "f5d1f602a3ccc5b5bad1d1524170e6d5";

module.exports = node;
