/**
 * @generated SignedSource<<1e48e899daf617278884098f36dfc0e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfAssessmentTemplatesQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SelfAssessmentTemplateList_query"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelfAssessmentTemplateList_organisation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfAssessmentTemplatesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessmentTemplatePagination",
        "kind": "LinkedField",
        "name": "selfAssessmentTemplatePagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessmentTemplate",
            "kind": "LinkedField",
            "name": "selfAssessmentTemplates",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "databaseId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "companyName",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasChildOrganisations",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8054e12a2ee61d7f3ddc2c2c341ba4be",
    "id": null,
    "metadata": {},
    "name": "SelfAssessmentTemplatesQuery",
    "operationKind": "query",
    "text": "query SelfAssessmentTemplatesQuery(\n  $limit: Int\n  $page: Int\n  $query: String\n  $sortBy: String\n  $sortDirection: String\n) {\n  ...SelfAssessmentTemplateList_query_1JgTUc\n  currentOrganisation {\n    ...SelfAssessmentTemplateList_organisation\n    id\n  }\n}\n\nfragment SelfAssessmentTemplateList_organisation on Organisation {\n  ...SelfAssessmentTemplate_organisation\n  hasChildOrganisations\n}\n\nfragment SelfAssessmentTemplateList_query_1JgTUc on Query {\n  selfAssessmentTemplatePagination(limit: $limit, page: $page, query: $query, sortBy: $sortBy, sortDirection: $sortDirection) {\n    selfAssessmentTemplates {\n      ...SelfAssessmentTemplate_selfAssessmentTemplate\n      id\n    }\n    totalCount\n  }\n}\n\nfragment SelfAssessmentTemplate_organisation on Organisation {\n  hasChildOrganisations\n}\n\nfragment SelfAssessmentTemplate_selfAssessmentTemplate on SelfAssessmentTemplate {\n  databaseId\n  name\n  organisation {\n    companyName\n    id\n  }\n}\n"
  }
};
})();

node.hash = "40edc00a11b67fb7e5d0c2affe152d8c";

module.exports = node;
