/**
 * @generated SignedSource<<13c5cb7e86228f3a20c4f1358accccf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "databaseId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "JobTitleConnection",
  "kind": "LinkedField",
  "name": "jobTitles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JobTitle",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "roles",
      "value": [
        "admin",
        "editor",
        "author"
      ]
    }
  ],
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "users",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": "users(roles:[\"admin\",\"editor\",\"author\"])"
},
v6 = [
  {
    "kind": "TypeDiscriminator",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewRiskLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Risk",
        "kind": "LinkedField",
        "name": "risk",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewRiskForm_risk"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewRiskLocalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Risk",
        "kind": "LinkedField",
        "name": "risk",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionalMeasure",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "impactScore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "measure",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "normParagraphIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "probabilityScore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "residualImpactScore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "residualProbabilityScore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "riskableId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "riskableType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "riskTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "threatExplanation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "threatIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "UsersInterface",
            "abstractKey": "__isUsersInterface"
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "JobTitlesInterface",
            "abstractKey": "__isJobTitlesInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "794f1862956ea2c8d8a347c1f9f1b964",
    "id": null,
    "metadata": {},
    "name": "NewRiskLocalQuery",
    "operationKind": "query",
    "text": "query NewRiskLocalQuery(\n  $id: ID!\n) {\n  risk(id: $id) {\n    jobTitles {\n      nodes {\n        __typename\n        databaseId\n        name\n        id\n      }\n    }\n    users(roles: [\"admin\", \"editor\", \"author\"]) {\n      __typename\n      databaseId\n      name\n      id\n    }\n    ...NewRiskForm_risk\n    id\n  }\n}\n\nfragment NewRiskForm_jobTitlesInterface on JobTitlesInterface {\n  __isJobTitlesInterface: __typename\n  jobTitles {\n    nodes {\n      databaseId\n      name\n      id\n    }\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n\nfragment NewRiskForm_risk on Risk {\n  additionalMeasure\n  impactScore\n  jobTitleId\n  measure\n  normParagraphIds\n  probabilityScore\n  residualImpactScore\n  residualProbabilityScore\n  riskableId\n  riskableType\n  riskTypes\n  status\n  threatExplanation\n  threatIds\n  userId\n  ...OwnerFilter_usersInterface_4rSJYf\n  ...NewRiskForm_jobTitlesInterface\n}\n\nfragment OwnerFilter_usersInterface_4rSJYf on UsersInterface {\n  __isUsersInterface: __typename\n  users(roles: [\"admin\", \"editor\", \"author\"]) {\n    databaseId\n    name\n    id\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n"
  }
};
})();

node.hash = "950ab39961745b7a0734af01434d6410";

module.exports = node;
