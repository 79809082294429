/**
 * @generated SignedSource<<e39bc8bd43e75b1230bdf8181450fe7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerFieldName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SatQuestionField",
  "kind": "LinkedField",
  "name": "satQuestionFields",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SatAnswer",
  "kind": "LinkedField",
  "name": "satAnswers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "satQuestionFieldId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfAssessmentTemplateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessmentTemplate",
        "kind": "LinkedField",
        "name": "selfAssessmentTemplate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SatQuestion",
            "kind": "LinkedField",
            "name": "satQuestions",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SatField",
                "kind": "LinkedField",
                "name": "satFields",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfAssessmentTemplateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SelfAssessmentTemplate",
        "kind": "LinkedField",
        "name": "selfAssessmentTemplate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SatQuestion",
            "kind": "LinkedField",
            "name": "satQuestions",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SatField",
                "kind": "LinkedField",
                "name": "satFields",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v9/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2c9701ce41a72db7b3621123fd23206",
    "id": null,
    "metadata": {},
    "name": "SelfAssessmentTemplateQuery",
    "operationKind": "query",
    "text": "query SelfAssessmentTemplateQuery(\n  $id: ID!\n) {\n  selfAssessmentTemplate(id: $id) {\n    answerFieldName\n    databaseId\n    id\n    name\n    satQuestionFields {\n      databaseId\n      id\n      name\n      position\n    }\n    satQuestions {\n      databaseId\n      id\n      position\n      satAnswers {\n        answer\n        databaseId\n        id\n        position\n      }\n      satFields {\n        databaseId\n        name\n        satQuestionFieldId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "2954b195f2234074e5f60453734e544a";

module.exports = node;
