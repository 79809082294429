/**
 * @generated SignedSource<<7030ece4c250542de99741185b03a883>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewAuditQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Unit",
            "kind": "LinkedField",
            "name": "units",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AuditFormQuery_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewAuditQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "currentOrganisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Unit",
            "kind": "LinkedField",
            "name": "units",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ManualChapter",
        "kind": "LinkedField",
        "name": "manualChapters",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "manualParagraphIds",
            "storageKey": null
          },
          (v0/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ManualParagraph",
            "kind": "LinkedField",
            "name": "manualParagraphs",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/),
              (v3/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NormOrganisation",
        "kind": "LinkedField",
        "name": "normOrganisations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Norm",
            "kind": "LinkedField",
            "name": "norm",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d96a51dacb5bf6d93a3ea380cba4ef6d",
    "id": null,
    "metadata": {},
    "name": "NewAuditQuery",
    "operationKind": "query",
    "text": "query NewAuditQuery {\n  currentOrganisation {\n    units {\n      name\n      id\n    }\n    id\n  }\n  ...AuditFormQuery_query\n}\n\nfragment AuditFormQuery_query on Query {\n  manualChapters {\n    id\n    databaseId\n    manualParagraphIds\n    ...ExpandableCheckboxQuery_manualChapter\n  }\n  normOrganisations {\n    norm {\n      databaseId\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment ExpandableCheckboxQuery_manualChapter on ManualChapter {\n  databaseId\n  name\n  number\n  manualParagraphIds\n  manualParagraphs {\n    databaseId\n    name\n    number\n    id\n  }\n}\n"
  }
};
})();

node.hash = "912c7d737acd01001382685f42f522a1";

module.exports = node;
