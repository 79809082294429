/**
 * @generated SignedSource<<61ee3fe2edd75619886e7bb346b19ed2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Member",
    "kind": "LinkedField",
    "name": "currentMember",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "receiveNotificationOnDeviationCreation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "receiveProductNewsItems",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "receiveFavoritesUpdates",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b113a3bcec02d6c5fc535e97c6abe46e",
    "id": null,
    "metadata": {},
    "name": "NotificationsQuery",
    "operationKind": "query",
    "text": "query NotificationsQuery {\n  currentMember {\n    id\n    receiveNotificationOnDeviationCreation\n    receiveProductNewsItems\n    receiveFavoritesUpdates\n  }\n}\n"
  }
};
})();

node.hash = "4af507b11407c6cf9c6353e938df9ecf";

module.exports = node;
