/**
 * @generated SignedSource<<c5281d39159fa506762b79ddee7f42e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionFields",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerFieldName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SaQuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaQuestionnaire",
        "kind": "LinkedField",
        "name": "saQuestionnaire",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SaQuestionnaireResults_saQuestionnaire"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SaAnswer",
            "kind": "LinkedField",
            "name": "saAnswers",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SaQuestion",
                "kind": "LinkedField",
                "name": "saQuestion",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaAnswerOption",
                    "kind": "LinkedField",
                    "name": "saAnswerOptions",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessment",
            "kind": "LinkedField",
            "name": "selfAssessment",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaQuestionnaireQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaQuestionnaire",
        "kind": "LinkedField",
        "name": "saQuestionnaire",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SaAnswer",
            "kind": "LinkedField",
            "name": "saAnswers",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SaQuestion",
                "kind": "LinkedField",
                "name": "saQuestion",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SaAnswerOption",
                    "kind": "LinkedField",
                    "name": "saAnswerOptions",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v9/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SelfAssessment",
            "kind": "LinkedField",
            "name": "selfAssessment",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v3/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f633e1013f0dbccb4df4aaee9ac3421",
    "id": null,
    "metadata": {},
    "name": "SaQuestionnaireQuery",
    "operationKind": "query",
    "text": "query SaQuestionnaireQuery(\n  $id: ID!\n) {\n  saQuestionnaire(id: $id) {\n    ...SaQuestionnaireResults_saQuestionnaire\n    __typename\n    id\n    progress\n    userId\n    saAnswers {\n      answer\n      id\n      saQuestion {\n        position\n        questionFields\n        saAnswerOptions {\n          answer\n          score\n          id\n        }\n        id\n      }\n    }\n    selfAssessment {\n      answerFieldName\n      databaseId\n      name\n      id\n    }\n    user {\n      name\n      id\n    }\n  }\n}\n\nfragment SaQuestionnaireResults_saQuestionnaire on SaQuestionnaire {\n  saAnswers {\n    answer\n    id\n    score\n    saQuestion {\n      position\n      questionFields\n      id\n    }\n  }\n  selfAssessment {\n    answerFieldName\n    id\n  }\n}\n"
  }
};
})();

node.hash = "27bc89c01224ed0e07d40685f1dffe37";

module.exports = node;
