/**
 * @generated SignedSource<<194876c597572008bdd35bdb4eef75cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "idProviderUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginContainerQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginContainerQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d55f2c9a868973d50e1e057b58dd92ee",
    "id": null,
    "metadata": {},
    "name": "LoginContainerQuery",
    "operationKind": "query",
    "text": "query LoginContainerQuery {\n  idProviderUrl\n}\n"
  }
};
})();

node.hash = "4db9a3276be877ff462c71e1ad0871b0";

module.exports = node;
